import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/crud.service';
import { Classification } from './classification.model';

@Injectable({
    providedIn: 'root'
})
export class ClassificationService extends CrudService<Classification> {
    protected initApiResource() {
        this.apiResource = 'classifications';
    }
}
