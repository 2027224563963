import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[capAutoFocus]'
})
export class CapAutoFocusDirective implements AfterViewInit {

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.el.nativeElement.focus();
        this.el.nativeElement.select();
    }
}
