import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/index';
import { debounceTime } from 'rxjs/internal/operators';
import { CapScrollingListOptions, CapScrollingListArchivedState, getBooleanForCapScrollingListArchivedState } from './shared/cap-scrolling-list.model';
import * as _ from 'lodash';

@Component({
    selector: 'cap-scrolling-list',
    templateUrl: './cap-scrolling-list.component.html',
    styleUrls: ['./cap-scrolling-list.component.scss']
})
export class CapScrollingListComponent implements OnInit {
    private CapScrollingListArchivedState = CapScrollingListArchivedState;

    private _defaultOptions: CapScrollingListOptions = {
        collectionName: 'items',
        debounceTime: 500,
        searchPlaceHolder: 'Search...',
        archivedInitialState: CapScrollingListArchivedState.Normal
    };

    @Input() public items: any[] = [];
    @Input() public options: CapScrollingListOptions = this._defaultOptions;

    @Output() public onSearch: EventEmitter<string> = new EventEmitter();
    @Output() public onScrolledToEnd: EventEmitter<any> = new EventEmitter();
    @Output() public onArchiveButtonClicked: EventEmitter<CapScrollingListArchivedState> = new EventEmitter();

    @ViewChild('listSearchInput') public listSearchInput: ElementRef;
    @ContentChild(TemplateRef) public itemTemplate: TemplateRef<any>;

    private _archivedState = CapScrollingListArchivedState.Normal;

    public searchSubject: Subject<string> = new Subject();

    public ngOnInit() {
        this.searchSubject.pipe(debounceTime(this.options.debounceTime)).subscribe((searchQuery) => {
            this.onSearch.emit(searchQuery);
        });

        this._archivedState = this.options.archivedInitialState;
    }

    public focusSearch() {
        this.listSearchInput.nativeElement.focus();
    }

    public toggleArchived(): void {
        // Switch the state and emit the state to other components
        switch (this._archivedState) {
            case CapScrollingListArchivedState.Normal:
                this._archivedState = CapScrollingListArchivedState.Archived;
                break;
            case CapScrollingListArchivedState.Archived:
                this._archivedState = CapScrollingListArchivedState.Normal;
                break;
            default:
                this._archivedState = CapScrollingListArchivedState.Normal;
                break;
        }

        this.onArchiveButtonClicked.emit(this._archivedState);
    }

    public onScroll(event: any) {
        const offsetHeight = event.target.offsetHeight;
        const scrollTop = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;

        const hasScrolledToBottom = offsetHeight + scrollTop >= scrollHeight;

        if (hasScrolledToBottom) {
            this.onScrolledToEnd.emit();
        }
    }

    public isArchived(): boolean {
        return getBooleanForCapScrollingListArchivedState(this._archivedState);
    }
}
