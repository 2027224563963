export enum UserRole {
    ADMIN = 'ROLE_ADMIN',
    USER = 'ROLE_USER'
}

export class User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    roles: UserRole[];
}