import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Farmer, FarmerDescriptor } from '../../../farmers/farmers.model';
import { OnInit, EventEmitter, Component, Input, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FarmersService } from '../../../farmers/farmers.service';
import { finalize, debounceTime } from 'rxjs/operators';
import { SelectOptions } from './shared/cap-farmer-search.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'cap-farmer-search',
    templateUrl: './cap-farmer-search.component.html',
    styleUrls: ['./cap-farmer-search.component.scss']
})
export class CAPFarmerSearchComponent implements OnInit, OnChanges {
    @Input() public enableArchive: boolean = false;
    @Input() public options: SelectOptions = {};
    @Input() public farmerId: number;
    @Output() public change: EventEmitter<any> = new EventEmitter();
    @Output() public clear: EventEmitter<any> = new EventEmitter();

    @ViewChild('select') public select: any;

    public farmers$: BehaviorSubject<Farmer[]> = new BehaviorSubject([]);
    public farmersLoading: boolean;
    public farmerSearchSubject: Subject<string> = new Subject();
    public selectedFarmer: Farmer;

    public showArchived: boolean = false;
    public searchQuery: string = '';

    public listSubscription: Subscription;

    public constructor(private formBuilder: FormBuilder,
                       protected farmerService: FarmersService) {
    }

    public ngOnInit() {

        if (this.options.selectedOnInit) {
            this.select.focus();
        }


        const initialSubscription = this.farmers$.subscribe((result: Farmer[]) => {
            if (this.options.selectDefault) {
                if (!this.selectedFarmer) {
                    this.selectedFarmer = result[0];
                }
            }

            if (this.selectedFarmer) {
                this.change.emit(this.selectedFarmer);
            }

            if (initialSubscription) {
                initialSubscription.unsubscribe();
            }
        });

        this.updateFarmers();

        this.farmerSearchSubject.pipe(
            debounceTime(500)
        ).subscribe((searchString: string) => {
            this.searchQuery = searchString;
            this.updateFarmers();
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.farmerId && changes.farmerId.currentValue) {
            // Select farmer to reflect the farmerId change.
            this.farmers$.subscribe((result: Farmer[]) => {
                this.selectedFarmer = result.find((f) => f.id === this.farmerId);
            });
        } else {
            this.selectedFarmer = null;
        }
    }

    public updateFarmers() {
        this.farmersLoading = true;

        this.listSubscription = this.farmerService.getAllFarmers({
            search: this.searchQuery,
            showArchived: this.showArchived
        }).pipe(
            finalize(() => this.farmersLoading = false)
        ).subscribe((result: Farmer[]) => {
            this.farmers$.next(result);
        });
    }

    public setSelectedFarmer(farmer: Farmer): void {
        this.farmers$.subscribe((result: Farmer[]) => {
            this.selectedFarmer = result.find((f) => f.id === farmer.id);
        });
    }

    public changed(event: FarmerDescriptor) {
        if (!event || !event.id) {
            return; // Sometimes the component may throw null/undefined
        }

        // Reset the current search when selecting a farmer.
        this.searchQuery = '';
        this.change.emit(event);
    }

    public getPlaceholder(): string {
        return this.options.placeholder ? this.options.placeholder : '';
    }

    public setShowArchived(showArchived: boolean = false) {
        this.showArchived = showArchived;
        this.updateFarmers();
    }
}
