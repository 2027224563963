import {CrudSubResourceService} from '../../../shared/crud-sub-resource.service';
import {Merchant} from '../../shared/merchant.model';
import {PriceList} from './price-list.model';
import {MerchantQuote} from '../../../quotes/shared/merchant-quote.model';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {CommodityType} from '../../../shared/models/commodity-type.model';
import {Input} from "@angular/core";
import { FormGroup } from '@angular/forms';

export class PriceListService extends CrudSubResourceService<Merchant, PriceList> {

    protected initApiResource() {
        this.apiResource = 'merchants';
        this.apiSubResource = 'price-lists';
    }

    public getQuotes(parentResourceId: number, id: number, commodityType: CommodityType): Observable<MerchantQuote[]> {
        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());

        return this.http.get(`${this.getResourceUrl(parentResourceId)}/${id}/quotes`, {
            params
        });
    }

    public importQuotesCSV(parentResourceId: number, id: number, commodityType: CommodityType, file: File, form: FormGroup): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('merchantQuoteFile', file);
        formData.append('merchantContactMethodId', form.controls['merchantContactMethodId'].value);
        formData.append('merchantContactId', form.controls['merchantContactId'].value);

        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());

        return this.http.post(`${this.getResourceUrl(parentResourceId)}/${id}/import`, formData, {
            params
        });
    }

    public getQuotesCSV(parentResourceId: number, id: number, commodityType: CommodityType, categoryIds?: string) {
        let params: HttpParams = new HttpParams();
        if (categoryIds) {
            params = params.append('categoryIds', categoryIds);
        }
        params = params.append('commodityType', commodityType.toString());

        return this.http.get<any>(`${this.getResourceUrl(parentResourceId)}/${id}/export`,
            {
                headers: this.getHeaders(),
                params,
                observe: 'response',
                responseType: 'blob' as 'json'
            }
        );
    }
}
