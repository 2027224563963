import { CrudService } from '../../../shared/crud.service';
import { Observable } from 'rxjs/index';
import { ChemicalMerchantPrice } from './prices.model';
import { HttpParams } from '@angular/common/http';
import { SeedPriceOverride } from "../../shared/seed-price.model";
import { map, tap } from "rxjs/internal/operators";
import { SeedPricesResponse } from "../../shared/seed-price-response.model";

export class PricesService extends CrudService<ChemicalMerchantPrice> {
    protected initApiResource() {
        this.apiResource = 'prices';
    }

    public getChemicalMerchantPrices(chemCodeId: number, farmerId: number = null, merchantId: number = null): Observable<ChemicalMerchantPrice[]> {
        const endpoint = `${this.apiResource}/chemicals/${chemCodeId}`;
        let params: HttpParams = new HttpParams();
        if (farmerId) {
            params = params.append('farmerId', farmerId.toString());
        }
        if (merchantId) {
            params = params.append('merchantId', merchantId.toString());
        }

        return this.http.get(`${this.apiBase}/${endpoint}`, {
            headers: this.getHeaders(),
            params
        });
    }

    public getSeedMerchantPrices(cropId: number, farmerId: number = null, merchantId: number = null): Observable<SeedPricesResponse> {
        const endpoint = `${this.apiResource}/seeds/${cropId}`;
        let params: HttpParams = new HttpParams();
        if (farmerId) {
            params = params.append('farmerId', farmerId.toString());
        }
        if (merchantId) {
            params = params.append('merchantId', merchantId.toString());
        }

        return this.http.get(`${this.apiBase}/${endpoint}`, {
            params
        }).pipe(map((res: any) => {
            // Unwrap the data envelope
            return {
                seedPrices: res.data.seedPrices,
                haulageBandOverrides: res.data.haulageBandOverrides,
                priceOverrides: res.data.priceOverrides
            };
        }));
    }

    public overrideSeedPrice(data: any): Observable<SeedPriceOverride> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/seeds/override`, data);
    }

    public clearOverridePrice(id: number): Observable<any> {
        return this.http.delete(`${this.apiBase}/${this.apiResource}/seeds/override/${id}`);
    }
}
