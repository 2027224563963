import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { MerchantsService } from '../../../merchants/merchants.service';
import { Merchant } from '../../../merchants/shared/merchant.model';
import { SelectOptions } from '../cap-farmer-search/shared/cap-farmer-search.model';

@Component({
    selector: 'cap-merchant-search',
    templateUrl: './cap-merchant-search.component.html',
    styleUrls: ['./cap-merchant-search.component.scss']
})
export class CAPMerchantSearchComponent implements OnInit {
    @ViewChild('select') public select: any;

    @Input()
    public set merchant(merchant: Merchant) {
        this._selectedMerchantId = merchant.id;
    }

    @Input()
    public set merchantId(merchantId: number) {
        this._selectedMerchantId = merchantId;
    }

    @Input() public options: SelectOptions = {};
    @Output() public change: EventEmitter<any> = new EventEmitter();
    @Output() public clear: EventEmitter<any> = new EventEmitter();

    public merchants$: Subject<Merchant[]> = new Subject();

    public merchantsLoading: boolean;

    private _selectedMerchantId: number;

    public merchantSearchSubject: Subject<string> = new Subject();

    public _isInvalid: boolean;

    public changed(event: Merchant) {
        if (!event) {
            return; // Sometimes the component may throw null/undefined
        }

        this.change.emit(event);
    }

    public cleared(event: any) {
        this.clear.emit(event);
    }

    @Input()
    public set isInvalid(value: boolean) {
        this._isInvalid = value;
    }

    public get isInvalid(): boolean {
        return this._isInvalid;
    }

    public constructor(
        protected merchantService: MerchantsService
    ) {
    }

    public ngOnInit() {
        if (this.options.selectedOnInit) {
            this.select.focus();
        }

        if (this.options.selectDefault) {
            const initialSubscription = this.merchants$.subscribe((result: Merchant[]) => {
                if (result.length === 0) {
                    return;
                }

                let merchant = result.find((m) => m.id === this._selectedMerchantId);
                if (!merchant) {
                    // If the merchant couldn't be found, select first entry instead.
                    merchant = result[0];
                }

                this._selectedMerchantId = merchant.id;

                this.change.emit(merchant);

                initialSubscription.unsubscribe();
            });
        }

        this.updateMerchants();

        this.merchantSearchSubject.pipe(
            debounceTime(500)
        ).subscribe((searchString: string) => {
            this.updateMerchants(searchString);
        });
    }

    public updateMerchants(searchTerm: string = '') {
        if (!searchTerm) {
            searchTerm = '';
        }

        this.merchantsLoading = true;
        this.merchantService.search(searchTerm, {
            showChems: true,
            showSeeds: true,
            showFerts: true,
            archived: false
        }).pipe(
            finalize(() => this.merchantsLoading = false)
        ).subscribe((result) => {
            this.merchants$.next(result);
        });
    }

    public getPlaceholder(): string {
        return this.options.placeholder ? this.options.placeholder : '';
    }

    public clearSelection() {
        this._selectedMerchantId = null;
    }
}
