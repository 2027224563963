import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Farmer } from '../farmers.model';
import { Observable, of } from 'rxjs';
import { DeliveryAddressModel } from './delivery-address.model';
import { Contact } from './contacts.model';

export class ContactsService extends CrudSubResourceService<Farmer, Contact> {
    protected initApiResource() {
        this.apiResource = 'farmers';
        this.apiSubResource = 'contacts';
    }
}
