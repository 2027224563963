import { CrudService } from '../shared/crud.service';
import { Farmer } from '../../farmers/farmers.model';
import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Merchant } from '../shared/merchant.model';

export class FarmerReferenceService extends CrudSubResourceService<Farmer, Merchant> {
    protected initApiResource() {
        this.apiResource = 'merchants';
        this.apiSubResource = 'farmer-references';
    }
}
