import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionManagerService } from '../session/shared/sessionmanager.service';
import { serverType } from './apiprovider.service';
import { AuthHttpClient } from '../core/authhttpclient';
import { RestService } from './rest.service';

export enum ServerTypeMap {
    Dev = 'dev',
    QA = 'qa',
    CAP = 'cap',
    Local = 'local'
}

export class BuildGuard {
    private _serverType = serverType;
    public ServerTypeMap = ServerTypeMap;

    public get serverType() {
        return this._serverType;
    }

    public isClientFacing(): boolean {
        return this.isCAP() || this.isQA();
    }

    public isCAP(): boolean {
        return ServerTypeMap.CAP === this._serverType;
    }

    public isDev(): boolean {
        return ServerTypeMap.Dev === this._serverType;
    }

    public isQA(): boolean {
        return ServerTypeMap.QA === this._serverType;
    }

    public isLocal(): boolean {
        return ServerTypeMap.Local === this._serverType;
    }

}
