import { Farmer } from './farmers.model';
import { Observable, of } from 'rxjs';
import { CrudService } from '../shared/crud.service';
import { HttpParams } from '@angular/common/http';
import { FarmersFilter } from './shared/farmers-filter';

export class FarmersService extends CrudService<Farmer> {
    public getAllFarmers({ search = '', showArchived = false }: FarmersFilter): Observable<any[]> {
        let params = new HttpParams();
        params = params.append('search', search);
        params = params.append('archived', showArchived.toString());

        return this.http.get<Farmer[]>(this.getSearchURL(), {
            headers: this.getHeaders(),
            params
        });
    }

    public getSearchURL(): string {
        return `${this.apiBase}/${this.apiResource}/search`;
    }

    public sendMerchantSignUpPack(farmerId: number, data: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${farmerId}/send-merchant-sign-up-pack`, data, {
            headers: this.getHeaders()
        });
    }

    protected initApiResource() {
        this.apiResource = 'farmers';
    }
}
