import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Observable } from 'rxjs';

@Component({
    selector: 'cap-datatable',
    templateUrl: './cap-datatable.component.html'
})
export class CAPDataTableComponent implements OnInit, OnDestroy {
    @Input() columns: any;
    @Input() observable: Observable<any> = null;
    @Input() readOnly: boolean = false;
    @Input() items: any[];
    @Input() itemsPerPage: number = 10;
    @Output() onRowClicked = new EventEmitter<any>();
    @Output() onDeleteClicked = new EventEmitter<any>();


    ngOnInit() {

    }

    ngOnDestroy() {
    }

    rowClicked(item: any) {
    }

    deleteClicked(item: any) {
    }

    addRow(item: any) {

    }

    updateRow(item: any, predicate) {
    }

    removeRow(predicate) {

    }
}
