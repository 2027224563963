import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs';
import { PurchaseOrder } from '../../orders/shared/order.model';

@Injectable()
export class ReportService extends CrudService<PurchaseOrder> {
    protected initApiResource() {
        this.apiResource = 'reports';
    }

    public previewReport(formData: any, type: string): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${type}`,
            formData,
            {
                headers: this.getHeaders(),
                observe: 'response',
                responseType: 'blob' as 'json'
            });
    }

    public previewBudgetReport(formData: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/budget-called-off`,
            formData,
            {
                headers: this.getHeaders(),
                observe: 'response',
                responseType: 'blob' as 'json'
            });
    }

    public generateAcreageReport(formData: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/acreage`, formData, {
            headers: this.getHeaders(),
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    public generateFinalTermsReport(formData: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/final-terms`, formData, {
            headers: this.getHeaders(),
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    public generateTgwOverrideReport(): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/tgw-override`, {}, {
            headers: this.getHeaders(),
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    public generateProductsReport(formData: any, slug: string): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/product-report/${slug}`, formData, {
            headers: this.getHeaders(),
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }
}
