import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { sign, decode, TokenExpiredError } from 'jsonwebtoken';
import { UserRole } from '../../admin/users/shared/user.model';
import * as _ from 'lodash';
import { OrderListSearchService } from '../../orders/order-list/shared/order-list-search.service';


@Injectable()
export class SessionManagerService {
    // @TODO might be better to glob all of these together, to be able to clear them
    // all at once.
    private _tokenString: string;
    private _refreshToken: string;
    private _token: any;
    private _currentUser: string;
    private _roles: UserRole[];

    constructor(private http: HttpClient,
                private orderListSearchService: OrderListSearchService) {
        this.initializeFromLocalStorage();
    }

    private initializeFromLocalStorage() {
        this._tokenString = this.tokenString;
        this._refreshToken = this.refreshToken;
        this._token = decode(this.tokenString);
        if (this.token) {
            this.user = this.token.username;
        }
        this.roles = this.parseRoles(this.token);
        this.parseRoles(this._token);
// console.log(this.tokenString, this._token, this._currentUser, this._roles);
    }

    get tokenString() {
        return this._tokenString ? this._tokenString : localStorage.getItem('token');
    }

    get refreshToken() {
        return this._refreshToken ? this._refreshToken : localStorage.getItem('refreshToken');
    }

    get token() {
        return this._token;
    }

    set token(token: any) {
// console.log(`Token: ${token}`);
        this._tokenString = token;
        this._token = decode(this.tokenString);

        if (this.token) {
            this.user = this.token.username;
        }

        this.setLocalStorageVal('token', this.tokenString);
        this.roles = this.parseRoles(this.token);
    }

    set refreshToken(refreshToken: string) {
        this._refreshToken = refreshToken;
        this.setLocalStorageVal('refreshToken', this.refreshToken);
    }

    private parseRoles(token: any): UserRole[] {
        if (!token) {
            return;
        }

        return _.map(token.roles, r => r as UserRole);
    }

    private setLocalStorageVal(key: string, value: any) {
        if (value) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }

    get roles(): UserRole[] {
        return this._roles;
    }

    set roles(roles: UserRole[]) {
        this._roles = roles;
    }

    get user() {
        return this._currentUser;
    }

    set user(user: string) {
        this._currentUser = user;
    }

    clear() {
        this.user = null;
        this.roles = null;
        this._tokenString = null;
        this._token = null;

        this.setLocalStorageVal('token', null);
        this.setLocalStorageVal('refreshToken', null);
    }

    logout() {
        this.orderListSearchService.clear();
        this.clear();
    }

    hasToken() {
        return !!this.token;
    }

    isLoggedIn(): Observable<any> {
        return new Observable<any>((observer: Subscriber<any>) => {
// console.log('Token Boolean ', !!this.token);
// console.log('Token Value ', this.token);

            if (!this.hasToken()) {
                observer.next({
                    loggedIn: false,
                    reason: 'notAuthenticated'
                });
                return observer.complete();
            }
            observer.next({
                loggedIn: true
            });
            return observer.complete();
            // For now - there is no `check` api endpoint, so this will stop here.
        });
    }
}
