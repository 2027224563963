import { appLocale } from './time-locale.service';
import * as moment from 'moment';

export class DateService {
    public format(date: string, format: string = 'DD/MM/YYYY'): string {
        return date ? moment(date).locale(appLocale).format(format) : '';
    }

    public toDate(date: string): Date {
        return moment(date).locale(appLocale).toDate();
    }
}
