import { Merchant } from './shared/merchant.model';
import { Observable, of } from 'rxjs';
import { CrudService } from '../shared/crud.service';
import { HttpParams } from '@angular/common/http';
import { MerchantVarietyConfiguration } from '../quotes/shared/merchant-quote.model';

export class MerchantsService extends CrudService<Merchant> {
    public search(search: string, formValue: {
        showChems?: boolean;
        showSeeds?: boolean;
        showFerts?: boolean;
        archived?: boolean;
    } = {
        showChems: false,
        showSeeds: false,
        showFerts: false,
        archived: false
    }): Observable<any[]> {
        let params = new HttpParams();
        params = params.append('search', search);

        Object.keys(formValue).forEach((key) => {
            const formItem = formValue[key];

            if (formItem) {
                params = params.set(`${key}`, `${formItem}`);
            }
        });

        return this.http.get<Merchant[]>(this.getSearchURL(), {
            headers: this.getHeaders(),
            params
        });
    }

    public getVarietyThousandGrainWeight(merchantId: number, varietyId: string): Observable<{ thousandGrainWeight: number }> {
        return this.http.get(`${this.apiBase}/${this.apiResource}/${merchantId}/varieties/${varietyId}/tgw`);
    }

    public getVarietyConfiguration(merchantId: number, varietyId: string): Observable<MerchantVarietyConfiguration> {
        return this.http.get(`${this.apiBase}/${this.apiResource}/${merchantId}/varieties/${varietyId}/config`);
    }

    public saveVarietyConfiguration(merchantId: number, varietyId: string, data: any): Observable<MerchantVarietyConfiguration> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${merchantId}/varieties/${varietyId}/config`, data);
    }

    public getSearchURL(): string {
        return `${this.apiBase}/${this.apiResource}/search`;
    }

    protected initApiResource() {
        this.apiResource = 'merchants';
    }
}
