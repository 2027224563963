import { CrudSubResourceService } from '../../../shared/crud-sub-resource.service';
import { Farmer } from '../../farmers.model';
import { ClientDetailsFile } from './client-documents.model';
import { Observable } from 'rxjs';

export class ClientDetailsFilesService extends CrudSubResourceService<Farmer, ClientDetailsFile> {
    public readFile(parentResourceId: number, fileId: number): Observable<any> {
        return this.http.get<any>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${fileId}`, {
            headers: this.getDocumentHeaders(),
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    public readFiles(parentResourceId: number): Observable<ClientDetailsFile[]> {
        return this.http.get<ClientDetailsFile[]>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, {
            headers: this.getHeaders()
        });
    }

    public updateFiles(parentResourceId: number, data: any): Observable<ClientDetailsFile> {
        return this.http.put<ClientDetailsFile>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, data, {
            headers: this.getHeaders()
        });
    }

    public deleteFile(parentResourceId: number, id: number): Observable<any> {
        return this.http.delete<ClientDetailsFile>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${id}`, {
            headers: this.getHeaders()
        });
    }

    public uploadFile(parentResourceId: number, file: File): Observable<any> {
        // Create a multipart-formdata object to upload
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);

        return this.http.post<ClientDetailsFile>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, formData, {
            headers: this.getHeaders()
        });
    }

    protected initApiResource() {
      this.apiResource = 'farmers';
      this.apiSubResource = 'documents';
    }
}
