import { FertiliserBrandDescriptor } from '../../../fertiliser-type-management/shared/fertiliser-brand.model';
import { FertiliserManufacturer } from '../../../fertiliser-type-management/shared/fertiliser-type.model';
import { CrudService } from '../../../shared/crud.service';

export class FertiliserManufacturerService extends CrudService<FertiliserManufacturer> {

    private apiSubResource = 'fertiliser-brands';

    protected initApiResource() {
        this.apiResource = 'fertiliser-manufacturers';
    }

    public listFertiliserBrands(id: number) {
        return this.http.get<FertiliserBrandDescriptor[]>(`${this.apiBase}/${this.apiResource}/${id}/${this.apiSubResource}`, {
            headers: this.getHeaders()
        });
    }
}
