import { Injectable } from '@angular/core';
import {
    HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest,
    HttpXhrBackend
} from '@angular/common/http';
import { Observable, ObservableInput, throwError, of } from 'rxjs';
import { retryWhen, mergeMap, take, concat, delay, flatMap } from 'rxjs/operators';
import { SessionManagerService } from '../session/shared/sessionmanager.service';
import { AuthGuard } from '../auth/shared/authguard';
import { RequestOptions } from 'https';
import { LoginResponse } from '../auth/loginresponse';
import { AuthenticationService } from '../auth/auth.service';
import { UserNotifcationService } from '../shared/user-notification.service';
import { ApiErrorResponse } from '../shared/errors/api-error-response.model';

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: any;
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
    body?: any;
}

export function AuthHttpClientCreator(http: HttpClient,
                                      authService: AuthenticationService,
                                      sessionManager: SessionManagerService,
                                      authGuard: AuthGuard,
                                      userNotificationService: UserNotifcationService) {
    return new AuthHttpClient(http, authService, sessionManager, authGuard, userNotificationService);
}

@Injectable()
export class AuthHttpClient {

    constructor(protected http: HttpClient,
                protected authService: AuthenticationService,
                protected sessionManager: SessionManagerService,
                protected authGuard: AuthGuard,
                protected userNotificationService: UserNotifcationService) {
    }

    /**
     * GET request
     * @param {string} endPoint it doesn't need / in front of the end point
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.http.get<T>(endPoint, options).pipe(retryWhen(this.handleErrorAndRetry(this.authService, this.userNotificationService)));
    }

    /**
     * POST request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public post<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
        return this.http.post<T>(endPoint, params, options).pipe(retryWhen(this.handleErrorAndRetry(this.authService, this.userNotificationService)));
    }

    /**
     * PUT request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public put<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
        return this.http.put<T>(endPoint, params, options).pipe(retryWhen(this.handleErrorAndRetry(this.authService, this.userNotificationService)));
    }

    /**
     * DELETE request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.http.delete<T>(endPoint, options).pipe(retryWhen(this.handleErrorAndRetry(this.authService, this.userNotificationService)));
    }

    private handleErrorAndRetry(authService: AuthenticationService, userNotificationService: UserNotifcationService): (error: any) => any {
        return (error: any) => {
            return error.pipe(flatMap((err: any) => {
                if (err.status === 401) {
                    return authService.refreshTokenObservable();
                }

                if (err.status === 403) {
                    const errorResponse: ApiErrorResponse = err.error.error;

                    userNotificationService.notify('error', errorResponse.message, {
                        timeout: 0
                    });

                    return throwError({error: err});
                }

                if (err.status === 500) {
                    userNotificationService.notify('error', 'Something has gone wrong, please try again later or contact technical support.', {
                        timeout: 0
                    });
                }

                return throwError({error: err});
            }));
        };
    }
}
