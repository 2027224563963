import {
    Component, EventEmitter, Input, Output
} from '@angular/core';
import { PanelOptions } from './cap-sliding-panel.model';
import { trigger, transition, style, animate, query, animateChild, group } from '@angular/animations';
import { FocusableComponent } from '../../../focusable-component.interface';

@Component({
    selector: 'cap-sliding-panel',
    templateUrl: './cap-sliding-panel.component.html',
    styleUrls: ['./cap-sliding-panel.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                group([
                    style({backgroundColor: 'rgba(0,0,0,0)'}),
                    animate('500ms ease-in-out', style({backgroundColor: 'rgba(0,0,0,0.5)'})),
                    query('@slideInOut', animateChild()),
                    query('@fade', animateChild())
                ])
            ]),
            transition(':leave', [
                group([
                    animate('500ms ease-in-out', style({backgroundColor: 'rgba(0,0,0,0)'})),
                    query('@slideInOut', animateChild()),
                    query('@fade', animateChild())
                ])
            ]),
        ]),
        trigger('slideInOut', [
            transition(':enter', [
                style({left: '100%'}),
                animate('500ms ease-in-out', style({left: '0px'}))
            ]),
            transition(':leave', [
                style({left: '0px'}),
                animate('500ms ease-in-out', style({left: '100%'}))
            ])
        ]),
        trigger('fade', [
            transition(':enter', [
                style({opacity: 0}),
                animate('500ms ease-in-out', style({opacity: 1}))
            ]),
            transition(':leave', [
                style({opacity: 1}),
                animate('500ms ease-in-out', style({opacity: 0}))
            ])
        ])
    ]
})
export class CAPSlidingPanelComponent {
    @Input() public panelOptions: PanelOptions;
    @Input() public focusTarget: FocusableComponent;
    @Output() private onHidden: EventEmitter<any> = new EventEmitter();
    @Output() private onShown: EventEmitter<any> = new EventEmitter();

    private _hidden: boolean = true;

    public show() {
        this._hidden = false;
        this.onShownHandler();
    }

    public hide() {
        this._hidden = true;
        this.onHiddenHandler();
    }

    private onShownHandler() {
        if (this.focusTarget) {
            this.focusTarget.focusComponent();
        }
        this.onShown.emit();
    }

    private onHiddenHandler() {
        this.onHidden.emit();
    }

    public onEscapeHandler(event: KeyboardEvent) {
        event.stopPropagation();
        this.hide();
    }
}
