import { Component, ComponentFactoryResolver, Inject } from '@angular/core';

@Component({
    selector: 'spinner-overlay',
    template: `
        <div class="spinner-wrapper">
            <cap-loading-indicator></cap-loading-indicator>
        </div>
    `,
    styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent {
}
