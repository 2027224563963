import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cap-empty-state',
  templateUrl: './cap-empty-state.component.html',
  styleUrls: ['./cap-empty-state.component.scss']
})
export class CapEmptyStateComponent {
    @Input() public iconClass: string;
    @Input() public text: string;
}
