import { Injectable } from '@angular/core';
import { CrudService } from '../../../shared/crud.service';
import { PlantBreeder } from './plant-breeder.mode';

@Injectable({
  providedIn: 'root'
})
export class PlantBreederService extends CrudService<PlantBreeder> {
    protected initApiResource() {
        this.apiResource = 'plant-breeders';
    }
}
