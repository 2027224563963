import { Address } from '../../farmers/shared/address';
import { ContactDetails } from '../../farmers/shared/contact.details';
import { FarmerDescriptor } from '../../farmers/farmers.model';
import { CommodityType } from '../../shared/models/commodity-type.model';

export interface OrderListItem {
    id: number;
    orderNumber: string;
    status: OrderStatus;
    dateRaised: string;
    dateStatement?: string;
    dateSent?: string;
    addressForDelivery?: Address;
    orderLineCount: number;
    farmer?: FarmerDescriptor;
}

export interface PurchaseOrder {
    id: number;
    orderNumber: string;
    account: string;
    merchantId?: number;
    deliveryAddressId?: number;
    merchantContactId?: number;
    deliveryMethod: DeliveryMethod;
    status: OrderStatus;
    type: CommodityType;
    dateRaised: string;
    dateSent?: string;
    dateStatement?: string;
    customerReference?: string;
    dueDeliveryNote?: string;
    comment?: string;
    specialInstructions?: string;
    addressSpecialInstructions?: string;
    addressForDelivery?: Address;
    contactDetailsForDelivery?: ContactDetails;
    addressForFarmer?: Address;
    contactDetailsForFarmer?: ContactDetails;
    gateCode?: string;
    storeCode?: string;
    farmer?: FarmerDescriptor;
    orderLines?: OrderLine[];
    addressLink?: string;
    what3Words?: string;
    paymentRequiredNote?: string;
}

export interface OrderLine {
    reference: string;
    merchantQuoteId: number;
    invoicePrice: string;
    invoiceTotal: string;
    merchantId: number;
    merchantCode: string;
    merchantName: string;
    productName: string;
    productInformation: any;
    priceListName: string;
    quantity: number;
    comment?: string;

    bagSizeId: number;
    bagSizeKilogrammeCapacity: number;
    bagSizeName: string;

    modifierDescriptors?: ModifierDescriptor[];
    modifiers?: OrderLineModifier[];
    adjustments?: OrderLineAdjustment[];
}

export interface OrderLineModifier {
    label: string;
    amount: string;
}
export interface OrderLineAdjustment {
    label: string;
    description: string;
    amount: string;
}

export interface ChemicalOrderLine extends OrderLine {
    chemCodeId: number;
    packSize: string;
    mappNumber: number;
}

export interface SeedOrderLine extends OrderLine {
    cropId: number;
    cropType: string;
    orderUnit: number;
    unitName: string;
    varietyId: number;
    seedDressingName: string;
    haulageBand: string;
    bagSizeId: number;
    bagSizeName: string;
    hectares: number;
    seedsPerMeterSquared: number;
    netPrice: string;
    netTotal: string;
    isOrderedByArea: boolean;
}

export interface FertiliserOrderLine extends OrderLine {
    fertiliserTypeId: number;
    fertiliserTypeName: string;
    fertiliserBrandId: number;
    bagSizeId: number;
    bagSizeName: string;
    bagSizeKilogrammeCapacity: number; //float
    haulageBandId: number;
    haulageBandName: string;
    netPrice: string;
    priceOverride: boolean;
    orderUnitName: string;
    netTotal: string;
    financeAdjustment: {
        label: string;
        creditMonthLocation: FinanceAdjustmentLocation;
        creditMonth: string; // Date
        creditMonthMultiplier: number;
        costPerUnitPerMonth: number; // float
        costPerUnitPerMonthLocation: FinanceAdjustmentLocation;
    }
}

export enum FinanceAdjustmentLocation {
    FromNetPrice = 1,
    FromFertiliserFinanceOption = 2
}

export interface OrderLineDifference {
    key: string;
    property: string;
    value: string;
}

export interface SendingLogEntry {
    subject: string;
    toEmail: string;
    userName: string;
    userEmail: string;
    date: Date;
}

export interface ModifierDescriptor {
    id?: number;
    name: string;
    amount: number;
    orderType?: number;
}

export enum OrderStatus {
    Pending = 1,
    Sent = 2,
    Cancelled = 3
}

export enum OrderStatusString {
    Pending = 'Pending',
    Sent = 'Sent',
    Cancelled = 'Cancelled'
}

export enum DeliveryMethod {
    Delivery = 1,
    Collection = 2
}

export enum OrderState {
    Default = 0,
    Amended = 1,
    Cancelled = 2,
    CancelOrder = 3 // Used only on the front-end to detect whether the user would like to send the cancel notification _and_ cancel the order
}

// LEGACY

export enum OrderType {
    Chemical = 1,
    Seed = 2,
    Fertiliser = 3
}

export enum OrderRouteSlug {
    Chemical = 'chemical',
    Seed = 'seed',
    Fertiliser = 'fertiliser'
}

export enum OrderTypeString {
    Chemical = 'Chemical',
    Seed = 'Seed',
    Fertiliser = 'Fertiliser'
}

export interface OrderTypeItem {
    id: number;
    name: string;
}

export interface CalculateTonnageRequest {
    unit: number;
    hectares: number;
    seedsPerMeterSquared: number;
    thousandGrainWeight: number;
}

export interface OrderLineActionResponse {
    specialInstructions: string;
    paymentRequiredNote: string;
    deliveryPeriodNote: string;
    orderLine: OrderLine;
}

export enum OrderModalMode {
    Create = 1,
    Update = 2,
    Restart = 3
}
