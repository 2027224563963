import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MerchantPreference } from '../../../../merchants/shared/merchant.model';

@Component({
    selector: 'preference-indicator',
    templateUrl: './preference-indicator.component.html',
    styleUrls: ['./preference-indicator.component.scss']
})
export class PreferenceIndicatorComponent implements OnChanges {
    @Input() public preference: MerchantPreference;

    public preferenceConfig: { icon: string; class: string; description: string; };

    public MerchantPreference = MerchantPreference;

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.preference.currentValue) {
            return;
        }

        switch (+changes.preference.currentValue) {
            default:
            case MerchantPreference.Indifferent:
                break;
            case MerchantPreference.Preferred:
                this.preferenceConfig = {
                    icon: 'fas fa-check',
                    class: 'preferred',
                    description: 'Preferred'
                };
                break;
            case MerchantPreference.NotPreferred:
                this.preferenceConfig = {
                    icon: 'fas fa-times',
                    class: 'not-preferred',
                    description: 'Not Preferred'
                };
                break;
            case MerchantPreference.Warning:
                this.preferenceConfig = {
                    icon: 'fas fa-exclamation-triangle',
                    class: 'warning',
                    description: 'Warning'
                };
                break;
        }
    }
}
