import { CrudService } from '../../../shared/crud.service';
import { Observable } from 'rxjs';
import { ChemicalImportDifference, ChemicalBrand } from './chemical-import.model';
import { HttpParams } from '@angular/common/http';

export class ChemicalImportService extends CrudService<ChemicalImportDifference> {
    public getSearchURL(): string {
        return `${this.apiBase}/${this.apiResource}/search`;
    }

    protected initApiResource() {
        this.apiResource = 'liaison';
    }

    public uploadFile(file: File): Observable<any> {
        // Create a multipart-formdata object to upload
        const formData = new FormData();
        formData.append('liaisonFile', file);
        
        return this.http.post(`${this.apiBase}/${this.apiResource}/import`, formData);
    }

    public newMappLicense(liaisonActionId: number, overwrite: boolean): Observable<any> {
        const data = {
            overwrite: overwrite
        };

        return this.http.post(`${this.apiBase}/${this.apiResource}/${liaisonActionId}/new-mapp-license`, data, {
            headers: this.getHeaders()
        });
    }

    deleteBrand(liaisonActionId: number): Observable<any> {
        return this.http.delete(`${this.apiBase}/${this.apiResource}/${liaisonActionId}/delete-chemical-brand`, {
            headers: this.getHeaders()
        });
    }
}
