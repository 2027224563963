import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { OrderType, OrderRouteSlug, OrderTypeString, OrderTypeItem } from './order.model';

@Injectable()
export class OrderTypeParsingService {
    // Note: can return either boolean (false) or number
    public getOrderTypeFromSlug(url: string = '') {
        if (!url) {
            return false;
        }

        const segments = url.split('/');

        let orderType = null;

        segments.forEach((urlSegment) => {
            switch (urlSegment) {
                case OrderRouteSlug.Chemical:
                    orderType = OrderType.Chemical;
                    break;
                case OrderRouteSlug.Seed:
                    orderType = OrderType.Seed;
                    break;
                case OrderRouteSlug.Fertiliser:
                    orderType = OrderType.Fertiliser;
                    break;
                default:
                    break;
            }
        });

        if (!orderType) {
            return false;
        }

        return orderType;
    }

    public getStringFromOrderType(orderType: number): any {
        let typeString = '';

        switch (orderType) {
            case OrderType.Chemical:
                typeString = OrderTypeString.Chemical;
                break;
            case OrderType.Seed:
                typeString = OrderTypeString.Seed;
                break;
            case OrderType.Fertiliser:
                typeString = OrderTypeString.Fertiliser;
                break;
            default:
                break;
        }

        return typeString;
    }

    public getOrderTypeItemArray(): any {
        const orderTypes: OrderTypeItem[] = [];

        Object.keys(OrderType).map((key) => {
            if (Number(key)) {
                return;
            }

            orderTypes.push({ id: OrderType[key], name: this.getStringFromOrderType(OrderType[key]) });

            return;
        });

        return orderTypes;
    }
}
