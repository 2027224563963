import { ActivatedRouteSnapshot } from "@angular/router";

export abstract class BaseGuard{

    isPublicRoute(route: ActivatedRouteSnapshot){
        let url = route && route.url;
        if(!url){
            return false;
        }

// console.log(`Is this public? ${this.isPublicRouteUrl(url.toString())}`);

        return this.isPublicRouteUrl(url.toString());
    }

    isPublicRouteUrl(url: string){
        return url.toString().startsWith('/login') || url.toString().startsWith('login');
    }

}
