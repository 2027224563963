import { RestService } from './rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionManagerService } from '../session/shared/sessionmanager.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthHttpClient } from '../core/authhttpclient';
import { AuthenticatedRestService } from './authenticated-rest.service';
import { PaginationRequest } from './pagination/pagination.interface';

@Injectable()
export abstract class CrudService<T> extends AuthenticatedRestService {

    protected apiResource: string;

    constructor(
        http: AuthHttpClient,
        sessionManager: SessionManagerService
    ) {
        super(
            http,
            sessionManager
        );

        this.initApiResource();
    }

    public list({ showArchived = false }: { showArchived?: boolean } = {}): Observable<any[]> {
        let p = new HttpParams();
        p = p.append('archived', showArchived.toString());

        return this.http.get<T[]>(`${this.apiBase}/${this.apiResource}`, {
            headers: this.getHeaders(),
            params: p
        });
    }

    public create(data: T): Observable<any> {
        return this.http.post<T>(`${this.apiBase}/${this.apiResource}`, data, {
            headers: this.getHeaders()
        });
    }

    public read(id: number): Observable<any> {
        return this.http.get<T>(`${this.apiBase}/${this.apiResource}/${id}`, {
            headers: this.getHeaders()
        });
    }

    public update(id: number, data: T): Observable<any> {
        return this.http.put<T>(`${this.apiBase}/${this.apiResource}/${id}`, data, {
            headers: this.getHeaders()
        });
    }

    public delete(id: number): Observable<any> {
        return this.http.delete(`${this.apiBase}/${this.apiResource}/${id}`, {
            headers: this.getHeaders()
        });
    }

    public archive(id: number): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${id}/archive`, {
            headers: this.getHeaders()
        });
    }

    public restore(id: number): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${id}/restore`, {
            headers: this.getHeaders()
        });
    }

    protected abstract initApiResource();
}
