import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Select2Module } from 'ng2-select2';
/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from 'environments/environment';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { NoContentComponent } from './no-content';
import { XLargeDirective } from './home/x-large';
import { CoreModule } from "./core/core.module";
import { AppRoutingModule } from './app.routes';
import '../styles/styles.scss';
import '../styles/headings.css';
import { SharedModule } from "./shared/shared.module";
import { SnotifyModule, SnotifyService, ToastDefaults } from "ng-snotify";
import {AuthHttpClient, AuthHttpClientCreator} from "./core/authhttpclient";
import {AuthGuard} from "./auth/shared/authguard";
import {SessionManagerService} from "./session/shared/sessionmanager.service";
import { AuthenticationService } from "./auth/auth.service";
import {TokenInterceptor} from "./auth/shared/token.interceptor";
import {CanDeactivateGuard} from "./shared/can-deactivate-guard.service";
import { UserNotifcationService } from './shared/user-notification.service';
import {NgProgressModule} from "@ngx-progressbar/core";
import {NgProgressHttpModule} from "@ngx-progressbar/http";
import { WebStorageModule } from "ngx-store";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OverlayModule } from '@angular/cdk/overlay';

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState
];

interface StoreType {
    state: InternalStateType;
    restoreInputValues: () => void;
    disposeOldHosts: () => void;
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        NoContentComponent,
        XLargeDirective
    ],
    /**
     * Import Angular's modules.
     */
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        CoreModule,
        AppRoutingModule,
        Select2Module,
        SnotifyModule,
        WebStorageModule,
        NgProgressModule.forRoot({
            color: '#006838'
        }),
        NgProgressHttpModule.forRoot(),
        OverlayModule
    ],
    /**
     * Expose our Services and Providers into Angular's dependency injection.
     */
    providers: [
        environment.ENV_PROVIDERS,
        APP_PROVIDERS,
        { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        {
          provide: AuthHttpClient,
          useFactory: (httpClient: HttpClient, authService: AuthenticationService,  sessionManager: SessionManagerService, authGuard: AuthGuard, userNotificationService: UserNotifcationService) => {
            return new AuthHttpClient(httpClient, authService, sessionManager, authGuard, userNotificationService);
          },
          deps: [HttpClient, AuthenticationService, SessionManagerService, AuthGuard, UserNotifcationService]
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        CanDeactivateGuard
    ]
})
export class AppModule {
}
