import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionManagerService } from '../../session/shared/sessionmanager.service';
import { BaseGuard } from '../baseguard';

@Injectable()
export class AuthGuard extends BaseGuard implements CanActivate {
    constructor(
        private router: Router,
        private sessionManager: SessionManagerService
    ) {
        super();
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.isPublicRoute(route)) {
            return true;
        }

        return new Observable<boolean>((observer) => {
            this.sessionManager.isLoggedIn().subscribe((req) => {
                if (!req.loggedIn) {
                    this.onUnauthorized(req.status, state, req.reason);
                    observer.next(false);
                    return observer.complete();
                }

                observer.next(true);

                return observer.complete();
            }, (e) => {
                console.log("Can't communicate to server,", e);

                observer.next(true);

                return observer.complete();
            });
        });
    }

    public onUnauthorized(status: number, state?: RouterStateSnapshot, reason?: string) {
        let url = state ? state.url : null;

        if (!url) {
            url = this.router.url;
        }

        if (this.isPublicRouteUrl(url)) {
            return;
        }

        if ((reason && reason === 'notAuthenticated') || (status && status === 401)) {
            return this.router.navigate(['/login'], {
                queryParams: {
                    returnUrl: url
                }
            });
        }

        // Add catch for constant redirect.
        if (!url.startsWith('/portal')) {
            return this.router.navigate(['/portal']);
        }
    }
}
