import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SessionManagerService } from '../../session/shared/sessionmanager.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserRole } from '../../admin/users/shared/user.model';
import * as _ from 'lodash';
import {AuthHttpClient} from "../../core/authhttpclient";

@Injectable()
export class RoleManagerService {

    constructor(private http: AuthHttpClient, private sessionManager: SessionManagerService) {

    }

    private get user() {
        return this.sessionManager.user;
    }

    hasAccessToDomain(route: ActivatedRouteSnapshot): boolean {
        return true;
    }

    get isAdmin(): boolean {
        return this.hasRole(UserRole.ADMIN);
    }

    get isUser(): boolean {
        return this.hasRole(UserRole.USER);
    }

    hasRole(role: UserRole): boolean {
        return _.includes(this.sessionManager.roles, role);
    }
}
