import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { CapProduct, CapProductQuotingInfo } from './models/cap-product.model';
import { EMPTY, from, Observable, Subscription } from 'rxjs/index';
import { RestService } from './rest.service';
import { CommodityType } from './models/commodity-type.model';
import { HttpParams } from '@angular/common/http';
import { AuthHttpClient } from '../core/authhttpclient';
import { ConfirmModalService } from './confirm/confirm-modal.service';
import { switchMap } from 'rxjs/internal/operators';
import { ConfirmOptions } from './confirm/confirm-options';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';

@Injectable({
    providedIn: 'root'
})
export class CapProductService extends RestService {
    private apiResource = 'products';

    constructor(private http: AuthHttpClient,
                private confirmService: ConfirmModalService) {
        super();
    }

    public listProducts(commodityType: CommodityType): Observable<CapProduct[]> {
        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());

        return this.http.get(`${this.apiBase}/${this.apiResource}`, {
            params,
            headers: this.getHeaders()
        });
    }

    public getProductQuotingInfo(commodityType: CommodityType): Observable<CapProductQuotingInfo[]> {
        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());

        return this.http.get(`${this.apiBase}/${this.apiResource}/quoting-info`, {
            params,
            headers: this.getHeaders()
        });
    }

    public archive(id: number): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${id}/archive`, {
            headers: this.getHeaders()
        });
    }

    public restore(id: number): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${id}/restore`, {
            headers: this.getHeaders()
        });
    }

    public hasPrices(id: number): Observable<{ hasPrices: boolean }> {
        return this.http.get(`${this.apiBase}/${this.apiResource}/${id}/has-prices`, {
            headers: this.getHeaders()
        });
    }

    public deleteProduct(id: number): Observable<boolean> {
        // Need to return a observable so the view can disable buttons based on it
        return this.hasPrices(id).pipe(
            switchMap((result: { hasPrices: boolean }) => {
                let message: string = 'Are you sure you wish to delete this product?';
                if (result.hasPrices) {
                    message = 'This product has prices against it. This action will also delete the prices for this product. Are you sure you wish to continue?';
                }

                return this.confirmAndDeleteProduct(id, message);
            })
        );
    }

    /**
     * Returns a observable based of a promise that deletes the product or cancels depending of the outcome of the confirm modal.
     * Need to unsubscribe each time in the actions to prevent race conditions between the onHidden / onConfirm and prevent subscriptions stacking up.
     *
     * @param {number} id
     * @param {string} confirmMessage
     * @returns {Observable<boolean>}
     */
    public confirmAndDeleteProduct(id: number, confirmMessage: string): Observable<boolean> {
        const options = new ConfirmOptions();
        options.message = confirmMessage;

        const promise = new Promise<boolean>((resolve, reject) => {
            this.confirmService.show(options);

            // Need to be able to unsubscribe from these, so there are no multiple subscriptions / race conditions
            let confirmSubscription: Subscription;
            let cancelSubscription: Subscription;

            confirmSubscription = this.confirmService.onConfirm.subscribe(() => {
                confirmSubscription.unsubscribe();
                cancelSubscription.unsubscribe();

                this.http.delete(`${this.apiBase}/${this.apiResource}/${id}`, {
                    headers: this.getHeaders()
                }).subscribe(
                    () => resolve(true),
                    () => reject()
                );
            });

            cancelSubscription = this.confirmService.onHidden.subscribe(() => {
                confirmSubscription.unsubscribe();
                cancelSubscription.unsubscribe();
                resolve(false);
            });
        });

        return from(promise);
    }
}
