import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs/index';
import { PaginationMeta, PaginationRequest, PaginationResult } from '../pagination.interface';
import { debounceTime } from 'rxjs/internal/operators';
import { PreferenceSelectorComponent } from '../../../farmers/merchant-references/preference-selector/preference-selector.component';

@Component({
    selector: 'paginated-select',
    templateUrl: './paginated-select.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PaginatedSelectComponent),
        multi: true
    }]
})
export class PaginatedSelectComponent implements OnInit, ControlValueAccessor {
    @Input()
    public set pagination(result: PaginationResult<any>) {
        this.loading = false;
        if (!result) {
            return;
        }

        this.meta = result.meta;

        // If it's not the first page, append results
        if (result.meta.pagination.current_page > 1) {
            this.items = [...this.items.concat(result.data)];
            return;
        }

        this.items = result.data;
    }

    @Input() public placeholder: string = '';
    @Input() public multiple: boolean = false;
    @Input() public bindValue: string = 'id';
    @Input() public bindLabel: string = 'name';
    @Input() public selectValue: any = null;
    @Output() public onPagination = new EventEmitter<PaginationRequest>();

    public search$ = new Subject<string>();
    public loading: boolean = false;
    public items: any[] = [];

    private meta: PaginationMeta;
    private lastSearch: string = '';

    public onChange = (val: any) => {
    }

    public ngOnInit(): void {
        this.search$
            .pipe(debounceTime(200))
            .subscribe((search: string) => {
                this.lastSearch = search;

                const request: PaginationRequest = {
                    search,
                    page: 1,
                    maxResults: this.meta.pagination.per_page
                };

                this.onPagination.emit(request);
                this.loading = true;
            });
    }

    public writeValue(value: any): void {
        this.onChange(this.selectValue);
    }

    public registerOnChange(fn: (val: any) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
    }

    public onScrollToEnd() {
        if (this.meta.pagination.current_page >= this.meta.pagination.total_pages) {
            return;
        }

        const request: PaginationRequest = {
            search: this.lastSearch,
            page: this.meta.pagination.current_page + 1,
            maxResults: this.meta.pagination.per_page
        };

        this.onPagination.emit(request);
        this.loading = true;
    }
}
