import {
    ChangeDetectorRef,
    Directive, ElementRef, OnInit, Renderer2, ViewContainerRef, Input
} from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService, ComponentLoaderFactory } from 'ngx-bootstrap';
import { map, tap } from 'rxjs/internal/operators';
import { Subscription, Subject, Observable } from 'rxjs';
import { EventEmitter } from 'events';

export class CapDatepickerDirective extends BsDatepickerDirective {
    public firstConfigUpdate: boolean = true;
    public forceInputUpdate = new Subject<any>();

    set bsValue(value) {
        if (this._bsValue === value) {
            return;
        }

        // If this is the first value being provided, set the time to the current time.
        if (!this._bsValue && value && !this.firstConfigUpdate) {
            const now = new Date();
            value.setHours(now.getHours());
            value.setMinutes(now.getMinutes());
            value.setSeconds(now.getSeconds());
            value.setMilliseconds(now.getMilliseconds());
        }

        this._bsValue = value;
        this.bsValueChange.emit(value);
    }

    /**
     * This overrides the default classes behaviour
     *
     * With the change above, the config of the input doesn't fully update.
     *
     * Once the config has been set for the first time,
     * it forces the input to update automatically.
     *
     * The default behaviour is to format dates by `L`, which means that it will
     * use an American date format. As a result, once the config (and therefore the format)
     * changes, we should force-update the component (which is done in `CapDatepickerInputDirective`).
     *
     * After the user makes their first input change, the config (and other events fired by `ngx-bootstrap`)
     * will take over, so, once the forced update has completed, it'll return to the "normal" behaviour of
     * the datepicker.
     *
     * ... Had the above (the updating of times against the input)
     * been made "upstream" (with the ngx-bootstrap developers),
     * this workaround should no longer be required.
     */
    setConfig() {
        super.setConfig();

        if (this.firstConfigUpdate) {
            this.forceInputUpdate.next(this._bsValue);
            this.firstConfigUpdate = false;
            return;
        }
    }
}
