import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionManagerService } from '../session/shared/sessionmanager.service';
import { apiBase } from './apiprovider.service';
import { AuthHttpClient } from '../core/authhttpclient';
import { RestService } from './rest.service';
import { PaginationRequest } from './pagination/pagination.interface';

export abstract class AuthenticatedRestService extends RestService {

    constructor(
        protected http: AuthHttpClient,
        protected sessionManager: SessionManagerService) {
        super();
    }

    protected getHeaders() {
        let headers: HttpHeaders = super.getHeaders();
        headers = this.addAuth(headers);
        return headers;
    }

    protected getDocumentHeaders() {
        let headers: HttpHeaders = super.getHeaders();
        headers = this.addAuth(headers);
        return headers;
    }

    protected addAuth(headers) {
        if (this.sessionManager.hasToken()) {
            return headers.append('Authorization', `Bearer ${this.sessionManager.tokenString}`);
        }
        return headers;
    }

    protected getPaginationHttpParams(paginationRequest: PaginationRequest): HttpParams {
        let params: HttpParams = new HttpParams();
        params = params.append('page', paginationRequest.page.toString());
        params = params.append('maxResults', paginationRequest.maxResults.toString());

        console.log(paginationRequest);

        if (paginationRequest.search) {
            params = params.append('search', paginationRequest.search);
        }

        return params;
    }
}
