import { Injectable } from '@angular/core';
import { Snotify, SnotifyService, SnotifyToast, SnotifyToastConfig } from 'ng-snotify';

@Injectable()
export class UserNotifcationService {

    private notifications: {};
    private notificationQueue: {};
    private notificationTimeouts: {};

    constructor(private snotifyService: SnotifyService) {
        this.notificationQueue = {};
        this.notifications = {};
        this.notificationTimeouts = {};
    }

    public notify(type: string, message: string, config?: SnotifyToastConfig) {
        this.getNotification(type, message, config);
    }

    private getNotification(type: string,  message: string, config?: SnotifyToastConfig) {
        switch (type.toLowerCase()) {
            case ('success'):
                return this.snotifyService.success(message, config);
            case ('info'):
                return this.snotifyService.info(message, config);
            case ('warning'):
                return this.snotifyService.warning(message, config);
            case ('error'):
                return this.snotifyService.error(message, config);
            case ('confirm'):
                return this.snotifyService.confirm(message, config);
            case ('prompt'):
                return this.snotifyService.prompt(message, config);
            case ('html'):
                return this.snotifyService.html(message, config);
            case ('simple'):
            default:
                return this.snotifyService.simple(message, config);
        }
    }
}
