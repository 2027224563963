
import { CrudService } from '../../../shared/crud.service';
import { AgronomistCompany } from './agronomist-company.model';
import { HttpParams } from '@angular/common/http';

export class AgronomistCompaniesService extends CrudService<AgronomistCompany> {
    protected initApiResource() {
        this.apiResource = 'agronomist-companies';
    }

    public search(searchTerm: string, agronomistCompanyId?: number, showArchived?: boolean) {
        let params = new HttpParams();
        params = params.append('search', `${searchTerm}`);

        if (agronomistCompanyId) {
            params = params.append('agronomistCompany', `${agronomistCompanyId}`);
        }

        if (showArchived) {
            params = params.append('showArchived', `${showArchived}`);
        }

        return this.http.get<AgronomistCompany[]>(`${this.apiBase}/${this.apiResource}`, {
            headers: this.getHeaders(),
            params
        });
    }
}
