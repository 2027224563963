import { RestService } from './rest.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionManagerService } from '../session/shared/sessionmanager.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveIngredient } from '../admin/active-ingredients/shared/active-ingredient.model';
import { AuthHttpClient } from '../core/authhttpclient';
import { AuthenticatedRestService } from './authenticated-rest.service';
import {CommodityType} from './models/commodity-type.model';

@Injectable()
export abstract class CrudSubResourceService<P, T> extends AuthenticatedRestService {

    protected apiResource: string;
    protected apiSubResource: string;

    constructor(
        http: AuthHttpClient,
        sessionManager: SessionManagerService
    ) {
        super(
            http,
            sessionManager
        );

        this.initApiResource();
    }

    public list(
        parentResourceId: number,
        { showArchived = false }: { showArchived?: boolean } = {},
        { commodityType }: {commodityType?: CommodityType} = {},
        { priceListId }: {priceListId?: number} = {}
    ): Observable<any[]> {
        let p = new HttpParams();
        p = p.append('archived', showArchived.toString());
        if (commodityType) {
            p = p.append('commodityType', commodityType.toString());
        }
        if (priceListId) {
            p = p.append('priceListId', priceListId.toString());
        }

        return this.http.get<T[]>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, {
            headers: this.getHeaders(),
            params: p
        });
    }

    public create(parentResourceId: number, data: T): Observable<any> {
        return this.http.post<T>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, data, {
            headers: this.getHeaders()
        });
    }

    public read(parentResourceId: number, id: number): Observable<any> {
        return this.http.get<T>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${id}`, {
            headers: this.getHeaders()
        });
    }

    public update(parentResourceId: number, id: number, data: T): Observable<any> {
        return this.http.put<T>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${id}`, data, {
            headers: this.getHeaders()
        });
    }

    public delete(parentResourceId: number, id: number): Observable<any> {
        return this.http.delete(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${id}`, {
            headers: this.getHeaders()
        });
    }

    public archive(parentResourceId: number, id: number): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${id}/archive`, {
            headers: this.getHeaders()
        });
    }

    public restore(parentResourceId: number, id: number): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${id}/restore`, {
            headers: this.getHeaders()
        });
    }

    protected getResourceUrl(parentResourceId: number): string {
        return `${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`;
    }

    protected abstract initApiResource();
}
