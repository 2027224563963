import { Component, forwardRef, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { CommodityType } from '../../../models/commodity-type.model';
import { CommodityTypeUtils } from '../../../models/commodity-type-utils';

@Component({
    selector: 'commodity-select',
    templateUrl: './commodity-select.component.html'
})
export class CommoditySelectComponent implements ControlValueAccessor {
    @Input() public allowChemicals: boolean = true;
    @Input() public allowSeeds: boolean = true;
    @Input() public allowFertiliser: boolean = true;

    public selectedCommodity: CommodityType;

    public CommodityType = CommodityType;
    public CommodityTypeUtils = CommodityTypeUtils;

    private onChange = (val: CommodityType) => {
    };

    constructor(@Self() @Optional() public ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    public writeValue(value: CommodityType): void {
        this.selectedCommodity = value;
        this.onChange(value);
    }

    public registerOnChange(fn: (val: CommodityType) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
    }
}
