import {CrudSubResourceService} from '../../shared/crud-sub-resource.service';
import {Farmer} from '../farmers.model';
import {Observable} from 'rxjs';
import {Agronomist} from '../../admin/agronomist-companies/agronomists/shared/agronomist.model';
import {Address} from "./address";
import {ContactDetails} from "./contact.details";

export class ContactAddressLayoutService {
    public getAddressLayout(address: Address) {

        let map = [
            address.addressLineOne,
            address.addressLineTwo,
            address.town,
            address.county,
            address.postcode
        ].filter((a) => a && a.length > 0).map((a: string) => {
            return { icon: 'mr-2', value: a, last: false };
        });

        if (map && map.length > 0) {
            map[map.length - 1].last = true;
        }

        return map;
    }

    public getContactLayout(contactDetails: ContactDetails) {
        let map = [
            { value: contactDetails.name, icon: 'fa fa-user mr-2', requiresLink: false },
            { value: contactDetails.mobileNumber, icon: 'fa fa-mobile mr-2', requiresLink: false },
            { value: contactDetails.phoneNumber, icon: 'fa fa-phone mr-2', requiresLink: false },
            { value: contactDetails.faxNumber, icon: 'fa fa-fax mr-2', requiresLink: false }
        ].filter((a) => a && a.value && a.value.length > 0);
        return map;
    }
}
