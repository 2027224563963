import { Address } from '../../farmers/shared/address';
import { ContactDetails } from '../../farmers/shared/contact.details';
import { CommodityType } from '../../shared/models/commodity-type.model';

export interface MerchantDescriptor {
    id?: number;
    name?: string;
    code?: string;
}

export class Merchant {
    id: number;
    name: string;
    code: string;
    address: Address;
    contacts: MerchantContact[];
    notes: string;
    supplierOfSeeds: boolean;
    supplierOfChemicals: boolean;
    supplierOfFertilisers: boolean;
    paymentTermId: number;
    paymentTermName: string;
    paymentDate: number;
    isArchived: boolean;
}

export class MerchantContact {
    id: number;
    contactDetails: ContactDetails;
    address: Address;
    secondTelephone: string;
    mainChemicalsContact: boolean;
    mainFertiliserContact: boolean;
    mainSeedContact: boolean;
    chemicalOrders: boolean;
    fertiliserOrders: boolean;
    seedOrders: boolean;
    sendPurchaseOrdersViaEmail: boolean;
    newFarmers: boolean;
    isDepot: boolean;
    isArchived: boolean;
    manageQuotes: boolean;

    deserialize(input: any) {
        Object.assign(this, input);
        this.contactDetails = {};
        return this;
    }
}

export interface MerchantReference {
    merchantId: number;
    name: string;
    code: string;
    chemicalAccountNumber: string;
    seedAccountNumber: string;
    fertiliserAccountNumber: string;
    chemicalPreference?: MerchantPreference;
    seedPreference?: MerchantPreference;
    fertiliserPreference?: MerchantPreference;
}

export interface SpecialInstructionsTemplate {
    id: number;
    title: string;
    content: string;
    commodityType: CommodityType;
}

export enum MerchantPreference {
    Indifferent = 0,
    Preferred = 1,
    NotPreferred = 2,
    Warning = 3
}
