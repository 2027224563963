import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs';
import { MerchantQuote, OpenPricePreview } from './merchant-quote.model';
import { CommodityType } from '../../shared/models/commodity-type.model';
import { QuotingProgress } from './quotes.model';
import { HttpParams } from '@angular/common/http';

export class MerchantQuoteService extends CrudService<MerchantQuote> {
    protected initApiResource() {
        this.apiResource = 'merchant-quotes';
    }

    public bulkPricing(form: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/bulk-pricing`,
            form,
            {
                headers: this.getHeaders()
            }
        );
    }

    public fertiliserBulkPricing(form: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/fertiliser-bulk-pricing`,
            form,
            {
                headers: this.getHeaders()
            }
        );
    }

    public previewOpenPrices(form: any): Observable<OpenPricePreview[]> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/preview-open-prices`, form, {
            headers: this.getHeaders()
        });
    }

    public bulkClosePrices(form: any): Observable<OpenPricePreview[]> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/bulk-close-prices`, form, {
            headers: this.getHeaders()
        });
    }

    // [SJS]: Is this normal that all three calls here _don't_ have auth?
    //        Is the auth injected into the request before sending?

    public listProgress(commodityType: CommodityType): Observable<QuotingProgress[]> {
        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());

        return this.http.get(`${this.apiBase}/${this.apiResource}/progress`, {
            params
        });
    }

    public saveProgress(commodityType: CommodityType, formData: any): Observable<QuotingProgress> {
        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());

        return this.http.post(`${this.apiBase}/${this.apiResource}/progress`, formData, {
            params
        });
    }

    public deleteProgressItem(commodityType: CommodityType, quotingProgressId: number): Observable<void> {
        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());

        return this.http.delete(`${this.apiBase}/${this.apiResource}/progress/${quotingProgressId}`, {
            params
        });
    }
}
