import { Component } from '@angular/core';

@Component({
    selector: './cap-loading-indicator',
    template: `
        <div class="d-flex align-items-center justify-contents-center w-100 h-100 pt-3">
            <div class="w-100 text-center">
                <i class="fas fa-spinner fa-pulse" style="font-size: 36px;"></i>
                <br />
                <div class="mt-2 font-italic">Loading, please wait...</div>
            </div>
        </div>
    `
})
export class LoadingIndicatorComponent {

}
