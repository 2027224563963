import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Farmer } from '../farmers.model';
import { Observable } from 'rxjs';
import { Agronomist } from '../../admin/agronomist-companies/agronomists/shared/agronomist.model';

export class FarmerAgronomistService extends CrudSubResourceService<Farmer, Agronomist> {
  protected initApiResource() {
    this.apiResource = 'farmers';
    this.apiSubResource = 'agronomists';
  }

  addAgronomistToFarmer(parentResourceId: number, agronomistAllocation: any): Observable<any> {
    return this.http.post(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, agronomistAllocation, {
      headers: this.getHeaders()
    });
  }

  removeAgronomistFromFarmer(parentResourceId: number, agronomistId: number): Observable<any> {
    return this.http.delete(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${agronomistId}`, {
      headers: this.getHeaders()
    });
  }

  updateAgronomistByFarmer(parentResourceId: number, agronomist: Agronomist): Observable<any> {
    return this.http.put(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}/${agronomist.id}`, agronomist,{
      headers: this.getHeaders()
    });
  }
}
