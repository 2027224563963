import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiBase } from './apiprovider.service';

export abstract class RestService {
    protected apiBase: string = apiBase;

    protected getHeaders() {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Accept', 'application/json'); // Firefox
        return headers;
    }
}
