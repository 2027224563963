import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Farmer } from '../farmers.model';
import { ClientDetails } from "./client-details.model";
import { Observable } from 'rxjs';

export class ClientDetailsService extends CrudSubResourceService<Farmer, ClientDetails> {
  protected initApiResource() {
    this.apiResource = 'farmers';
    this.apiSubResource = 'client_details';
  }

  public readClientDetails(parentResourceId: number): Observable<ClientDetails> {
    return this.http.get<ClientDetails>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, {
        headers: this.getHeaders()
    });
  }

  public updateClientDetails(parentResourceId: number, data: any): Observable<ClientDetails> {
    return this.http.put<ClientDetails>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, data, {
      headers: this.getHeaders()
    });
  }
}
