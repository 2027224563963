import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs';
import { PurchaseOrder } from '../../orders/shared/order.model';

@Injectable()
export class StatementService extends CrudService<PurchaseOrder> {
    protected initApiResource() {
        this.apiResource = 'statements';
    }

    public previewStatement(data: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/preview`, data, {
            headers: this.getHeaders(),
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    public sendStatement(data: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/send`, data, {
            headers: this.getHeaders()
        });
    }
}
