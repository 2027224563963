import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'cap-saved-indicator',
    templateUrl: './form-saved-indicator.component.html'
})
export class FormSavedIndicatorComponent  {
    @Input() public form: FormGroup;
}
