import { CrudService } from '../../shared/crud.service';
import { Observable } from 'rxjs/index';
import { ChemCode, ChemCodeDescriptor, ChemicalBrand, ChemicalProduct } from './chemical.model';
import { HttpParams } from '@angular/common/http';
import { ChemProductInfo } from '../../quotes/shared/quotes.model';

export class ChemCodeService extends CrudService<ChemCode> {
    protected initApiResource() {
        this.apiResource = 'chem-codes';
    }

    public search(
        term: string,
        showArchived?: boolean
    ): Observable<ChemCodeDescriptor[]> {
        const searchEndpoint = `${this.apiBase}/${this.apiResource}/search`;
        let params = new HttpParams();

        params = params.append('search', `${term}`);
        params = params.append('archived', `${showArchived}`);

        return this.http.get(searchEndpoint, {
            headers: this.getHeaders(),
            params
        });
    }

    public listProducts(
        chemCodeId: number,
        {showArchived = false}: { showArchived?: boolean } = {}
    ): Observable<ChemicalProduct[]> {
        let params = new HttpParams();
        params = params.append('archived', showArchived.toString());

        return this.http.get(`${this.apiBase}/${this.apiResource}/${chemCodeId}/brands`, {
            headers: this.getHeaders(),
            params
        });
    }

    public createChemicalProduct(chemCodeId: number, brandId: number): Observable<ChemicalProduct> {
        const endpoint = `${this.apiResource}/${chemCodeId}/brands/${brandId}`;
        return this.http.post(`${this.apiBase}/${endpoint}`, {
            headers: this.getHeaders()
        });
    }
}
