import { CrudService } from '../../../shared/crud.service';
import { Observable } from 'rxjs/index';
import { ChemicalMerchantPrice } from './prices.model';
import { HttpParams } from '@angular/common/http';

import { FertiliserPriceOverride, FertiliserPricing, FertiliserTypePricing } from '../../shared/fertiliser-price.model';

// Note: this is _not_ a CRUD service, and therefore does not have standard CRUD endpoints"
export class FertiliserPricesService extends CrudService<FertiliserTypePricing> {
    protected initApiResource() {
        this.apiResource = 'prices/fertilisers';
    }

    public getFertiliserPrices(fertiliserTypeId: number, bagSizeId: number = null, farmerId: number = null, merchantId: number = null): Observable<FertiliserTypePricing> {
        const endpoint = `${this.apiResource}/${fertiliserTypeId}`;

        let params: HttpParams = new HttpParams();

        if (bagSizeId) {
            params = params.append('bagSizeId', bagSizeId.toString());
        }

        if (farmerId) {
            params = params.append('farmerId', farmerId.toString());
        }

        if (merchantId) {
            params = params.append('merchantId', merchantId.toString());
        }

        return this.http.get(`${this.apiBase}/${endpoint}`, {
            headers: this.getHeaders(),
            params
        });
    }

    public overrideFertiliserPrice(data: any): Observable<FertiliserPriceOverride> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/override`, data);
    }

    public clearOverridePrice(id: number): Observable<any> {
        return this.http.delete(`${this.apiBase}/${this.apiResource}/override/${id}`);
    }
}
