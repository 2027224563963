import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';
import { AppState } from './app.service';
import { setTheme } from 'ngx-bootstrap';

export const ROOT_SELECTOR = 'app';

@Component({
    selector: ROOT_SELECTOR,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        './app.component.css'
    ],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    public name = 'Crop Advisors';

    constructor(public appState: AppState) {
        setTheme('bs4');
    }

    public ngOnInit() {
        // console.log('Initial App State', this.appState.state);
    }
}
