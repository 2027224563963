
import { Injectable } from "@angular/core";
import { CrudService } from "../crud.service";
import { Attachment } from "./attachment.model";
import { Observable } from 'rxjs';

@Injectable()
export class AttachmentService extends CrudService<Attachment> {

    public getAttachments(types: string[] = []): Observable<any> {
        let url: string = `${this.apiBase}/${this.apiResource}`;
        if (types.length > 0) {
            url = `${url}?type=${types.join(',')}`;
        }

        return this.http.get<any>(url, {
            headers: this.getHeaders()
        });
    }

    protected initApiResource() {
        this.apiResource = 'attachments';
    }
}
