import { Injectable } from '@angular/core';
import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Merchant, SpecialInstructionsTemplate } from './merchant.model';
import { Observable } from 'rxjs/index';
import { HttpParams } from '@angular/common/http';
import { CommodityType } from '../../shared/models/commodity-type.model';

@Injectable({
    providedIn: 'root'
})
export class SpecialInstructionsTemplatesService extends CrudSubResourceService<Merchant, SpecialInstructionsTemplate> {

    public listTemplates(merchantId: number, commodityType?: CommodityType): Observable<SpecialInstructionsTemplate[]> {
        let params: HttpParams = new HttpParams();
        if (commodityType) {
            params = params.append('commodityType', commodityType.toString());
        }

        return this.http.get(`${this.apiBase}/${this.apiResource}/${merchantId}/${this.apiSubResource}`, {
            params
        });
    }

    protected initApiResource() {
        this.apiResource = 'merchants';
        this.apiSubResource = 'special-instructions-templates';
    }

}
