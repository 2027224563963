import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';

import { BaseGuard } from "../../auth/baseguard";
import { SessionManagerService } from "../../session/shared/sessionmanager.service";
import { RoleManagerService } from "./rolemanager.service";

@Injectable()
export class RoleGuard extends BaseGuard implements CanActivate {
    constructor(private router: Router, private sessionManager: SessionManagerService, private roleManager: RoleManagerService) {
        super();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.isPublicRoute(route)) {
            return true;
        }

        return this.roleManager.hasAccessToDomain(route);
    }
}
