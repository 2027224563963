import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthenticationService } from '../auth.service';
import { Observable } from 'rxjs';
import { SessionManagerService } from '../../session/shared/sessionmanager.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(protected sessionManager: SessionManagerService) {}
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.sessionManager.tokenString}`
      }
    });
    return next.handle(request);
  }
}
