import { NgModule } from '@angular/core';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CAPDataTableComponent } from './layout/ui-elements/cap-datatable.component';
import { CAPModalComponent } from './layout/ui-elements/cap-modal.component';
import { NgPipesModule } from 'ngx-pipes';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
    BsDatepickerModule,
    BsDropdownModule,
    ButtonsModule,
    CollapseModule,
    TabsModule,
    TooltipModule,
    PaginationModule, PopoverModule, SortableModule, AccordionModule, BsDatepickerDirective
} from 'ngx-bootstrap';
import { Select2Module } from 'ng2-select2';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';
import { ConfirmButtonDirective } from './confirm/confirm-button.directive';
import { DateService } from './date.service';
import { ContactDetailsComponent } from './layout/ui-elements/contact-details/contact-details.component';
import { AddressDetailsComponent } from './layout/ui-elements/address-details/address-details.component';
import { CapItemListComponent } from './layout/ui-elements/cap-item-list/cap-item-list.component';
import { CapFocusableDirective } from './directives/cap-focusable.directive';
import { CapAutoFocusDirective } from './directives/cap-auto-focus.directive';
import { CsvErrorService } from './csv-error.service';
import { CapEmptyStateComponent } from './layout/ui-elements/cap-empty-state/cap-empty-state.component';
import { CAPSlidingPanelComponent } from './layout/ui-elements/cap-sliding-panel/cap-sliding-panel.component';
import { BuildGuard } from './build-guard.service';
import { CAPFarmerSearchComponent } from './search/cap-farmer-search/cap-farmer-search.component';
import { CAPMerchantSearchComponent } from './search/cap-merchant-search/cap-merchant-search.component';
import { CapScrollingListComponent } from './layout/ui-elements/cap-scrolling-list/cap-scrolling-list.component';
import { CapSubmitIfValidDirective } from './directives/cap-submit-if-valid.directive';
import { CapSortableListComponent } from './layout/ui-elements/cap-sortable-list/cap-sortable-list.component';
import { PreferenceIndicatorComponent } from './layout/ui-elements/preference-indicator/preference-indicator.component';
import { PreferenceSelectorComponent } from '../farmers/merchant-references/preference-selector/preference-selector.component';
import { CommoditySelectComponent } from './forms/fields/commodity-select/commodity-select.component';
import { PaginatedSelectComponent } from './pagination/paginated-select/paginated-select.component';
import { LoadingIndicatorComponent } from './layout/ui-elements/loading-indicator/loading-indicator.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { ServerTypeService } from './server-type.service';
import { CapDatepickerDirective } from './directives/cap-datepicker.directive';
import { BsDatepickerInputDirective } from 'ngx-bootstrap/datepicker/bs-datepicker-input.directive';
import { CapDatepickerInputDirective } from './directives/cap-datepicker-input.directive';
import { FormSavedIndicatorComponent } from './layout/ui-elements/form-saved-indicator/form-saved-indicator.component';
import { CAPErrorListComponent } from './layout/ui-elements/cap-error-list/cap-error-list.component';

@NgModule({
    declarations: [
        CAPDataTableComponent,
        CAPModalComponent,
        CAPErrorListComponent,
        ConfirmModalComponent,
        ConfirmButtonDirective,
        ContactDetailsComponent,
        AddressDetailsComponent,
        CapItemListComponent,
        CapFocusableDirective,
        CapAutoFocusDirective,
        CapEmptyStateComponent,
        CAPSlidingPanelComponent,
        CAPFarmerSearchComponent,
        CAPMerchantSearchComponent,
        CapScrollingListComponent,
        CapSubmitIfValidDirective,
        CapSortableListComponent,
        PreferenceIndicatorComponent,
        CommoditySelectComponent,
        PaginatedSelectComponent,
        LoadingIndicatorComponent,
        SpinnerOverlayComponent,
        CapDatepickerInputDirective,
        CapDatepickerDirective,
        FormSavedIndicatorComponent
    ],
    imports: [
        Angular2PromiseButtonModule.forRoot({
            disableBtn: true,
            btnLoadingClass: 'is-loading',
            handleCurrentBtnOnly: false
        }),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgPipesModule,
        NgxDatatableModule,
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        SortableModule.forRoot(),
        Select2Module,
        NgSelectModule,
        ButtonsModule,
        AccordionModule.forRoot()
    ],
    exports: [
        Angular2PromiseButtonModule,
        CommonModule,
        ModalModule,
        FormsModule,
        ReactiveFormsModule,
        CAPDataTableComponent,
        CAPModalComponent,
        CAPErrorListComponent,
        NgPipesModule,
        NgxDatatableModule,
        TooltipModule,
        Select2Module,
        NgSelectModule,
        ButtonsModule,
        TabsModule,
        ConfirmButtonDirective,
        ConfirmModalComponent,
        CollapseModule,
        BsDropdownModule,
        BsDatepickerModule,
        ContactDetailsComponent,
        AddressDetailsComponent,
        CapItemListComponent,
        CapFocusableDirective,
        CapAutoFocusDirective,
        CapEmptyStateComponent,
        PaginationModule,
        CAPSlidingPanelComponent,
        PopoverModule,
        CAPFarmerSearchComponent,
        CAPMerchantSearchComponent,
        CapScrollingListComponent,
        CapSubmitIfValidDirective,
        SortableModule,
        CapSortableListComponent,
        AccordionModule,
        PreferenceIndicatorComponent,
        CommoditySelectComponent,
        PaginatedSelectComponent,
        LoadingIndicatorComponent,
        CapDatepickerDirective,
        CapDatepickerInputDirective,
        LoadingIndicatorComponent,
        FormSavedIndicatorComponent
    ],
    bootstrap: [
        ConfirmModalComponent,
        SpinnerOverlayComponent
    ],
    providers: [
        DateService,
        CsvErrorService,
        ServerTypeService,
        BuildGuard
    ]
})
export class SharedModule {
}
