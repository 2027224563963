import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/shared/authguard';
import { RoleGuard } from './role/shared/roleguard';
import { CanDeactivateGuard } from './shared/can-deactivate-guard.service';

export const ROUTES: Routes = [
    {
        path: '',
        children: [
            { path: '', redirectTo: '/login', pathMatch: 'full', canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            { path: 'login', loadChildren: './login#LoginModule', canDeactivate: [CanDeactivateGuard] },
            { path: 'portal', loadChildren: './portal#PortalModule', canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            { path: '**', redirectTo: '/login' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES, { useHash: true })],
    providers: [AuthGuard, RoleGuard],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
