import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import {
    NewPendingOrderRequest,
    ChangeOrderDetailsRequest,
    AddChemicalOrderLineRequest, EditChemicalOrderLineRequest
} from '../order-detail/shared/order-detail.model';
import { Observable } from 'rxjs';
import { ChemicalOrderLine, OrderLine, OrderLineActionResponse, PurchaseOrder, SendingLogEntry } from './order.model';
import { parseErrorBlob } from '../../shared/functions/parse-error-blob';
import { ContactRecipientRequest } from '../order-detail/order-contacts-modal/shared/order-contacts-modal.model';
import { CommodityType } from '../../shared/models/commodity-type.model';
import { CommodityTypeUtils } from '../../shared/models/commodity-type-utils';

@Injectable()
export class OrderService extends CrudService<PurchaseOrder> {
    protected initApiResource() {
        this.apiResource = 'orders';
    }

    public createNewPurchaseOrder(data: NewPendingOrderRequest): Observable<number> {
        return this.http.post(`${this.apiBase}/${this.apiResource}`, data, {
            headers: this.getHeaders()
        });
    }

    public update(id: number, data: ChangeOrderDetailsRequest): Observable<PurchaseOrder> {
        return this.http.put(`${this.apiBase}/${this.apiResource}/${id}`, data, {
            headers: this.getHeaders()
        });
    }

    public addOrderLine(id: number, data: any, commodityType: CommodityType): Observable<OrderLineActionResponse> {
        const slug: string = CommodityTypeUtils.toSlug(commodityType);
        return this.http.post(`${this.apiBase}/${this.apiResource}/${id}/order-lines/${slug}`, data);
    }

    public editOrderLine(id: number, reference: string, data: any, commodityType: CommodityType): Observable<OrderLineActionResponse> {
        const slug: string = CommodityTypeUtils.toSlug(commodityType);
        return this.http.put(
            `${this.apiBase}/${this.apiResource}/${id}/order-lines/${slug}/${reference}`,
            data
        );
    }

    public deleteOrderLine(id: number, reference: string): Observable<any> {
        return this.http.delete(`${this.apiBase}/${this.apiResource}/${id}/order-lines/${reference}`);
    }

    public previewOrder(id: number): Observable<any> {
        return this.http.get(`${this.apiBase}/${this.apiResource}/${id}/preview`, {
            headers: this.getHeaders(),
            responseType: 'blob' as 'json'
        });
    }

    public send(id: number): Observable<any> {
        return this.makeOrderStatusRequest(id, `send`);
    }

    public resend(id: number, contacts: ContactRecipientRequest): Observable<any> {
        return this.makeOrderStatusRequest(id, `resend`, contacts);
    }

    public cancel(id: number): Observable<any> {
        return this.makeOrderStatusRequest(id, `cancel`);
    }

    public reinstate(id: number): Observable<any> {
        return this.makeOrderStatusRequest(id, `reinstate`);
    }

    public makeOrderStatusRequest(id: number, path: string, payload: any = {}) {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${id}/${path}`,
            payload,
            {
                headers: this.getHeaders()
            });
    }

    public getSendingLog(id: number): Observable<SendingLogEntry[]> {
        return this.http.get(`${this.apiBase}/${this.apiResource}/${id}/sending-log`, {
            headers: this.getHeaders()
        });
    }

    public sendAll(payload: {
        ids: number[]
    }): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/send-all`,
            payload,
            {
                headers: this.getHeaders()
            });
    }
}
