import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../auth/auth.service';
import { SessionManagerService } from '../session/shared/sessionmanager.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { RoleManagerService } from '../role/shared/rolemanager.service';
import { UserService } from '../admin/users/shared/user.service';
import { AgronomistCompaniesService } from '../admin/agronomist-companies/shared/agronomist-companies.service';
import { AgronomistsService } from '../admin/agronomist-companies/agronomists/shared/agronomists.service';
import { ActiveIngredientService } from '../admin/active-ingredients/shared/active-ingredient.service';
import { ProductTypeService } from '../admin/product-types/shared/product-type.service';
import { BagSizeService } from '../admin/bag-sizes/shared/bag-size.service';
import { SeedDressingService } from '../admin/seed-dressings/shared/seed-dressing.service';
import { ChemicalManufacturerService } from '../admin/chemical-manufacturers/shared/chemical-manufacturer.service';
import { CommodityService } from '../admin/commodities/shared/commodity.service';
import { CroppingService } from '../admin/croppings/shared/cropping.service';
import { ChemicalImportService } from '../admin/chemicals-import/shared/chemicals-import.service';
import { FarmersService } from '../farmers/farmers.service';
import { DeliveryAddressService } from '../farmers/delivery-addresses/delivery-address.service';
import { ContactsService } from '../farmers/contacts/contacts.service';
import { MessagesService } from '../farmers/messages/messages.service';
import { ClientDetailsService } from '../farmers/client-details/client-details.service';
import { ClientDetailsFilesService } from '../farmers/client-details/client-documents/client-documents.service';
import { FarmerAgronomistService } from '../farmers/farmer-agronomists/farmer-agronomists.service';
import { AcreageService } from '../farmers/acreage/acreage.service';
import { ContactAddressLayoutService } from '../farmers/shared/contact-address-layout.service';
import { MerchantsService } from '../merchants/merchants.service';
import { MerchantContactService } from '../merchants/merchant-contacts/merchant-contact.service';
import { MerchantReferenceService } from '../farmers/merchant-references/merchant-reference.service';
import { AttachmentService } from '../shared/attachments/attachment.service';
import { FarmerReferenceService } from '../merchants/farmer-references/farmer-reference.service';
import { UserNotifcationService } from '../shared/user-notification.service';
import { PaymentTermService } from '../merchants/payment-terms/payment-term.service';
import { DialogService } from '../shared/dialog.service';
import { FormulationService } from '../admin/formulations/shared/formulation.service';
import { ChemCodeService } from '../chemicals/shared/chem-code.service';
import { ChemicalBrandService } from '../chemicals/shared/chemical-brand.service';
import { UnitService } from '../chemicals/shared/unit.service';
import { PricesService } from '../prices/price-list/shared/prices.service';
import { PriceListService } from 'app/merchants/price-lists/shared/price-list.service';

import { MerchantQuoteService } from 'app/quotes/shared/merchant-quote.service';

import { OrderTypeParsingService } from '../orders/shared/order-type-parsing.service';
import { MerchantModifierService } from '../merchants/merchant-modifiers/shared/merchant-modifier.service';
import { OrderListService } from '../orders/order-list/shared/order-list.service';
import { OrderStatusParsingService } from '../orders/shared/order-status-parsing.service';
import { OrderService } from '../orders/shared/order.service';
import { OrderRecipientService } from '../orders/order-detail/order-contacts-modal/shared/order-recipient.service';

import { ReportService } from '../reports/shared/report.service';
import { StatementService } from '../statements/shared/statement.service';
import { ClassificationService } from '../admin/classifications/shared/classification.service';
import { PlantBreederService } from '../admin/plant-breeders/shared/plant-breeder.service';
import { VarietyService } from '../seed-management/shared/variety.service';
import { CommodityTypeResolver } from '../shared/resolvers/commodity-type.resolver';
import { CapProductService } from '../shared/cap-product.service';
import { MerchantAdjustmentService } from '../merchants/shared/merchant-adjustment.service';
import { SpecialInstructionsTemplatesService } from '../merchants/shared/special-instructions-templates.service';
import { SpinnerOverlayService } from '../shared/spinner-overlay/spinner-overlay.service';
import { FertiliserManufacturerService } from '../admin/fertiliser-manufacturers/shared/fertiliser-manufacturer.service';
import { FertiliserPricesService } from '../prices/price-list/shared/fertiliser-prices.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [],
    declarations: [],
    entryComponents: [],
    providers: [
        AuthenticationService,
        SessionManagerService,
        RoleManagerService,
        UserService,
        ActiveIngredientService,
        UnitService,
        AgronomistCompaniesService,
        AgronomistsService,
        ProductTypeService,
        BagSizeService,
        SeedDressingService,
        ChemicalManufacturerService,
        FertiliserManufacturerService,
        CommodityService,
        CroppingService,
        ChemicalImportService,
        FarmersService,
        AcreageService,
        DeliveryAddressService,
        ContactsService,
        MessagesService,
        ClientDetailsService,
        ClientDetailsFilesService,
        FarmerAgronomistService,
        ContactAddressLayoutService,
        MerchantsService,
        MerchantContactService,
        MerchantReferenceService,
        FarmerReferenceService,
        AttachmentService,
        UserNotifcationService,
        PaymentTermService,
        DialogService,
        FormulationService,
        ChemCodeService,
        ChemicalBrandService,
        PricesService,
        FertiliserPricesService,
        PriceListService,
        MerchantQuoteService,
        OrderTypeParsingService,
        OrderStatusParsingService,
        MerchantModifierService,
        OrderListService,
        OrderService,
        OrderRecipientService,
        ReportService,
        StatementService,
        ClassificationService,
        PlantBreederService,
        VarietyService,
        CommodityTypeResolver,
        CapProductService,
        MerchantAdjustmentService,
        SpecialInstructionsTemplatesService,
        SpinnerOverlayService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
