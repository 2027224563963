export interface CapScrollingListOptions {
    collectionName?: string;
    debounceTime?: number;
    searchPlaceHolder?: string;
    archivedInitialState?: CapScrollingListArchivedState;
}

export enum CapScrollingListArchivedState {
    Normal = 1,
    Archived = 2
}

export function getBooleanForCapScrollingListArchivedState(initialState: CapScrollingListArchivedState): boolean {
    let state = false;

    switch (initialState) {
        case CapScrollingListArchivedState.Normal:
            state = false;
            break;
        case CapScrollingListArchivedState.Archived:
            state = true;
            break;
        default:
            state = false;
            break;
    }

    return state;
}
