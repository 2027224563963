import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[capFocusable]'
})
export class CapFocusableDirective {
    @Output() public focusReceived: EventEmitter<FocusEvent> = new EventEmitter();
    @Output() public focusLost: EventEmitter<FocusEvent> = new EventEmitter();

    constructor(private el: ElementRef) {}

    @HostListener('focus', ['$event'])
    onFocusReceived($event) {
        console.log('hello');
        this.focusReceived.emit($event);
    }

    @HostListener('focusout', ['$event'])
    onFocusChange($event) {
        if ($event.relatedTarget && this.el.nativeElement.contains($event.relatedTarget)) {
            return;
        }
        this.focusLost.emit($event);
    }
}
