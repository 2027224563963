import {
    ChangeDetectorRef,
    Directive, ElementRef, HostListener, OnInit, Optional, Renderer2, Self, ViewContainerRef
} from '@angular/core';
import { BsDatepickerConfig, BsDatepickerDirective, BsLocaleService, ComponentLoaderFactory } from 'ngx-bootstrap';
import { map, tap } from 'rxjs/internal/operators';
import { BsDatepickerInputDirective } from 'ngx-bootstrap/datepicker/bs-datepicker-input.directive';
import { CapDatepickerDirective } from './cap-datepicker.directive';
import { AbstractControl, ControlValueAccessor, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { parseDate } from 'ngx-bootstrap/chronos';
import { getLocale } from 'ngx-bootstrap/chronos/locale/locales';

@Directive({
    selector: '[bsDatepicker]'
})
export class CapDatepickerInputDirective {

    private bsDirective: BsDatepickerInputDirective;

    constructor(
        _picker: CapDatepickerDirective,
        _localeService: BsLocaleService,
        _renderer: Renderer2,
        _elRef: ElementRef,
        changeDetection: ChangeDetectorRef,
        @Self() @Optional() public ngControl: NgControl
    ) {
        // Extending BsDatepickerInputDirective did not work as any overriden methods were not called from the super class.
        // So instead I've instantiated it and use the bootstrap version to handle updating the input, where as this
        // class deals with Angular Forms ControlValueAccessor stuff.
        this.bsDirective = new BsDatepickerInputDirective(_picker, _localeService, _renderer, _elRef, changeDetection);
        this.ngControl.valueAccessor = this.bsDirective;

        /**
         * Force input update for date formatting (see `CapDatepickerDirective.setConfig()`)
         */
        _picker.forceInputUpdate.subscribe((value: any) => {
            this.bsDirective._setInputValue(value);
            _picker.forceInputUpdate.unsubscribe();
        });

        // Change didn't work as a HostListener, so adding it as a native event listener instead. (See bs-datepicker-input.directive.js for host decorators)
        _elRef.nativeElement.addEventListener('change', (event) => this.bsDirective.onChange(event));
    }

    @HostListener('keyup.esc')
    public hide() {
        this.bsDirective.hide();
    }

    @HostListener('blur')
    public blur() {
        this.bsDirective.onBlur();
    }
}
