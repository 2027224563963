import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Output,
    ViewChild
} from '@angular/core';
import { ConfirmOptions } from './confirm-options';

@Component({
    selector: 'confirm-modal',
    template: `
        <div class="modal-body text-center">
            {{ options ? options.message : 'Are you sure you want to proceed?' }} <br/>
            <button #yesBtn class="btn btn-danger mr-2"
                    type="button" (click)="yes()">
                {{ options ? options.okText : 'Yes' }}
            </button>
            <button class="btn btn-default"
                    type="button"
                    (click)="no()">
                {{ options ? options.cancelText : 'No' }}
            </button>
        </div>
    `
})
export class ConfirmModalComponent implements AfterViewChecked {
    @ViewChild('yesBtn') public yesBtn: ElementRef;
    @Output() public onYes: EventEmitter<any> = new EventEmitter();
    @Output() public onNo: EventEmitter<any> = new EventEmitter();

    public options: ConfirmOptions;

    public yes() {
        this.onYes.emit();
    }

    public no() {
        this.onNo.emit();
    }

    public ngAfterViewChecked(): void {
        this.yesBtn.nativeElement.focus();
    }
}
