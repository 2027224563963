import { LocalStorage } from 'ngx-store';
import { OrderStatus } from '../../shared/order.model';
import { Injectable } from '@angular/core';
import { CommodityType } from '../../../shared/models/commodity-type.model';
import { OrderListSearch } from './order-list.model';

@Injectable({
    providedIn: 'root'
})
export class OrderListSearchService {
    @LocalStorage()
    private orderListSearchParams: Array<{ commodityType: CommodityType, params: OrderListSearch }> = [];

    public retrieve(commodityType: CommodityType): OrderListSearch {
        const filters = this.findFiltersForCommodity(commodityType);

        if (!filters) {
            return null;
        }

        const params: OrderListSearch = filters.params;
        if (params.dateRange) {
            // Make sure its a Date object.
            params.dateRange = params.dateRange.map((date) => {
                return new Date(date);
            });
        }

        return params;
    }

    public store(commodityType: CommodityType, params: OrderListSearch): void {
        let filters = this.findFiltersForCommodity(commodityType);

        if (!filters) {
            filters = {commodityType, params};
            this.orderListSearchParams.push(filters);
            return;
        }

        filters.params = params;
        const cachedFilters = this.orderListSearchParams;
        const index = cachedFilters.findIndex((x) => x.commodityType === commodityType);
        cachedFilters[index] = filters;

        // Need to re-assign the property to persist to localstorage.
        this.orderListSearchParams = cachedFilters;
    }

    public clear() {
        this.orderListSearchParams = [];
    }

    private findFiltersForCommodity(commodityType: CommodityType) {
        return this.orderListSearchParams.find((x) => x.commodityType === commodityType);
    }
}
