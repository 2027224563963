import { Component, Input, OnInit } from '@angular/core';
import { Address } from "../../../../farmers/shared/address";
import { ContactAddressLayoutService } from "../../../../farmers/shared/contact-address-layout.service";

@Component({
    selector: 'address-details',
    templateUrl: './address-details.component.html'
})
export class AddressDetailsComponent {
    @Input()
    public set address(value: Address) {
        if (!value) {
            return;
        }
        this.addressComponents = this.contactAddressLayoutService.getAddressLayout(value);
    }

    public addressComponents: Array<{ icon: string, value: string, last: boolean }> = [];

    constructor(private contactAddressLayoutService: ContactAddressLayoutService) {
    }
}
