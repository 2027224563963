import { Directive, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { ConfirmState } from './confirm-state';
import { ConfirmModalComponent } from './confirm-modal.component';
import { BsModalService } from 'ngx-bootstrap';
import { ConfirmOptions } from './confirm-options';
import { ConfirmModalService } from './confirm-modal.service';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[confirmButton]'
})
export class ConfirmButtonDirective {
    @Input()
    public confirmOptions: ConfirmOptions;

    @Output()
    public confirm: EventEmitter<any> = new EventEmitter();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter();

    private _confirmSub: Subscription;
    private _cancelSub: Subscription;

    constructor(private confirmModalService: ConfirmModalService) {
    }

    public open() {
        const options = new ConfirmOptions();

        if (this.confirmOptions) {
            Object.assign(options, this.confirmOptions);
        }

        this.confirmModalService.show(options);

        this._confirmSub = this.confirmModalService.onConfirm.subscribe(() => {
            this.confirm.emit();
            this._removeSubscriptions();
        }, () => {
            this._removeSubscriptions();
        });

        this._cancelSub = this.confirmModalService.onCancel.subscribe(() => {
            this.cancel.emit();
            this._removeSubscriptions();
        }, () => {
            this._removeSubscriptions();
        });
    }

    /**
     * SJS: This fixes a bug whereby if the subscription isn't removed, then
     * a repeat of the same event will occur - retriggering the method call that is
     * bound to an event
     * @private
     */
    private _removeSubscriptions(): void {
        const confirmResult = this._confirmSub ? this._confirmSub.unsubscribe() : null;
        const cancelResult = this._cancelSub ? this._cancelSub.unsubscribe() : null;
    }

    @HostListener('click', ['$event'])
    public onHostClicked(event: MouseEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.open();
    }
}
