import { CrudService } from '../../../shared/crud.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { PurchaseOrder } from '../../shared/order.model';
import { CommodityType } from '../../../shared/models/commodity-type.model';
import { OrderCheckResponse, OrderLineFilter } from './order-list.model';

export class OrderListService extends CrudService<PurchaseOrder> {
    protected initApiResource() {
        this.apiResource = 'orders';
    }

    public getOrders(
        type: CommodityType,
        page: number,
        maxResults: number,
        orderLineFilter: OrderLineFilter
    ): Observable<any> {
        let httpParams = this.createHttpParamsFromFormData(orderLineFilter);
        httpParams = httpParams.append('commodityType', type.toString());

        if (page) {
            httpParams = httpParams.append('page', `${page}`);
        }

        if (maxResults) {
            httpParams = httpParams.append('maxResults', `${maxResults}`);
        }

        return this.http.get(`${this.apiBase}/${this.apiResource}`, {
            headers: this.getHeaders(),
            params: httpParams
        });
    }

    public getOrderSummary(orderId: number): Observable<any> {
        return this.http.get(`${this.apiBase}/${this.apiResource}/summary/${orderId}`, {
            headers: this.getHeaders()
        });
    }

    public pendingOrdersCount(): Observable<OrderCheckResponse> {
        return this.http.get(`${this.apiBase}/${this.apiResource}/pending-count`, {
            headers: this.getHeaders(),
        });
    }

    private createHttpParamsFromFormData(formData: any): HttpParams {
        let httpParams: HttpParams = new HttpParams();

        Object.keys(formData).map((key) => {
            if (Number(key) || !formData[key]) {
                return;
            }

            httpParams = httpParams.append(key, `${formData[key]}`);
            return;
        });

        return httpParams;
    }
}
