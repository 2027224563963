import { CrudService } from '../../shared/crud.service';
import { ChemicalBrand } from './chemical.model';
import { Observable } from 'rxjs/index';
import { HttpParams } from '@angular/common/http';

export class ChemicalBrandService extends CrudService<ChemicalBrand> {
    protected initApiResource() {
        this.apiResource = 'chemical-brands';
    }

    public search(searchQuery: string = ''): Observable<any[]> {
        const endpoint = `${this.apiBase}/${this.apiResource}/search`;

        let params = new HttpParams();

        params = params.set('search', `${searchQuery}`);

        return this.http.get(endpoint, {
            headers: this.getHeaders(),
            params
        });
    }
}
