import { Injectable } from '@angular/core';
import { OrderStatus, OrderStatusString } from './order.model';

export interface OrderStatusItem {
    name: string;
    id: number;
}

@Injectable()
export class OrderStatusParsingService {
    public getStringFromStatusId(statusId: number): any {
        let statusString = '';

        switch (statusId) {
            case OrderStatus.Pending:
                statusString = OrderStatusString.Pending;
                break;
            case OrderStatus.Sent:
                statusString = OrderStatusString.Sent;
                break;
            case OrderStatus.Cancelled:
                statusString = OrderStatusString.Cancelled;
                break;
            default:
                break;
        }

        if (!statusString) {
            return false;
        }

        return statusString;
    }

    public getOrderStatusArray() {
        const orderStatuses: OrderStatusItem[] = [];

        Object.keys(OrderStatus).map((key) => {
            if (Number(key)) {
                return;
            }

            orderStatuses.push({ id: OrderStatus[key], name: this.getStringFromStatusId(OrderStatus[key]) });

            return;
        });

        return orderStatuses;
    }
}
