import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Farmer } from '../farmers.model';
import { DeliveryAddress } from './delivery.address';

export class DeliveryAddressService extends CrudSubResourceService<Farmer, DeliveryAddress> {
    protected initApiResource() {
        this.apiResource = 'farmers';
        this.apiSubResource = 'delivery-addresses';
    }
}
