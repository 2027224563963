import { Injectable } from '@angular/core';
import { serverType } from './apiprovider.service';

@Injectable()
export class ServerTypeService {

    private serverType: string = serverType;

    /**
     * Returns a boolean status as to whether the server type given
     * indicates whether it is "live" or not
     */
    get isNotLive(): boolean {
        return this.serverType !== 'live';
    }

    /**
     * Gets the string representation of the server type requested
     */
    public getServerTypeDisplayString(): string {
        const serverTypeLower = this.serverType.toLowerCase();
        let serverTypeString = '';

        switch (serverTypeLower) {
            case 'qa':
                serverTypeString = 'QA';
                break;
            case 'local':
                serverTypeString = 'Local Instance';
                break;
            case 'cap':
                serverTypeString = 'CAP';
                break;
            case 'dev':
                serverTypeString = 'Development';
                break;
            case 'staging':
                serverTypeString = 'Staging';
                break;
            case 'testing_live':
                serverTypeString = 'Live - Testing';
                break;
            case 'live':
                serverTypeString = 'Live';
                break;
            default:
                serverTypeString = 'Other Build';
                break;
        }

        return serverTypeString;
    }

    public getServerType() {
        return serverType;
    }
}
