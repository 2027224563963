

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CommodityType } from '../models/commodity-type.model';
import { EMPTY, of } from 'rxjs/index';
import { CommodityTypeUtils } from '../models/commodity-type-utils';
import { Injectable } from '@angular/core';

@Injectable()
export class CommodityTypeResolver implements Resolve<CommodityType> {
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const slug = route.paramMap.get('commodityType');
        const commodityType = CommodityTypeUtils.slugToCommodityType(slug);

        if (!commodityType) {
            return EMPTY;
        }

        return of(commodityType);
    }
}
