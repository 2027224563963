import { CrudSubResourceService } from '../../../../shared/crud-sub-resource.service';
import { PurchaseOrder } from '../../../shared/order.model';
import { ContactRecipientResponse } from './order-contacts-modal.model';

export class OrderRecipientService extends CrudSubResourceService<PurchaseOrder, ContactRecipientResponse> {
    protected initApiResource() {
        this.apiResource = 'orders';
        this.apiSubResource = 'recipients';
    }
}
