import { CrudSubResourceService } from '../../../../shared/crud-sub-resource.service';
import { Agronomist } from './agronomist.model';
import { Observable } from 'rxjs';
import { AgronomistCompany } from '../../shared/agronomist-company.model';
import { HttpParams } from '@angular/common/http';

export class AgronomistsService extends CrudSubResourceService<AgronomistCompany, Agronomist> {

  protected initApiResource() {
        this.apiResource = 'agronomist-companies';
        this.apiSubResource = 'agronomists';
    }

    public listAllAgronomistCompanies(): Observable<AgronomistCompany[]> {
      return this.http.get<AgronomistCompany[]>(`${this.apiBase}/${this.apiResource}`, {
        headers: this.getHeaders()
      });
    }

    public listAgronomists(companyId: any): Observable<any[]> {
        return this.http.get<Agronomist[]>(`${this.apiBase}/${this.apiResource}/${companyId}/${this.apiSubResource}`, {
            headers: this.getHeaders()
        });
    }

    public createAgronomist(companyId: any, item: Agronomist): Observable<Agronomist> {
      return this.http.post<Agronomist>(`${this.apiBase}/${this.apiResource}/${companyId}/${this.apiSubResource}`, item, {
        headers: this.getHeaders()
      });
    }

    public updateAgronomist(companyId: any, item: Agronomist): Observable<Agronomist> {
      return this.http.put<Agronomist>(`${this.apiBase}/${this.apiResource}/${companyId}/${this.apiSubResource}/${item.id}`, item, {
        headers: this.getHeaders()
      });
    }

    public deleteAgronomist(companyId: any, id: number): Observable<any> {
        return this.http.delete<Agronomist>(`${this.apiBase}/${this.apiResource}/${companyId}/${this.apiSubResource}/${id}`, {
            headers: this.getHeaders()
        });
    }

    public search(searchTerm: string, agronomistCompanyId?: number, showArchived?: boolean): Observable<any> {
        let params = new HttpParams();
        params = params.append('search', `${searchTerm}`);

        if (agronomistCompanyId) {
            params = params.append('agronomistCompany', `${agronomistCompanyId}`);
        }

        if (showArchived) {
            params = params.append('showArchived', `${showArchived}`);
        }

        return this.http.get<Agronomist[]>(`${this.apiBase}/${this.apiResource}`, {
            headers: this.getHeaders(),
            params
        });
    }
}
