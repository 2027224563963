import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ContactDetails } from "../../../../farmers/shared/contact.details";

@Component({
    selector: 'contact-details',
    templateUrl: './contact-details.component.html'
})
export class ContactDetailsComponent {
    @Input() public contactDetails: ContactDetails;
}
