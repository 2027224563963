import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
    selector: 'cap-item-list',
    templateUrl: './cap-item-list.component.html',
    styleUrls: ['./cap-item-list.component.scss']
})
export class CapItemListComponent {
    @Input() public itemHeaderFn: (item: any) => string;
    @Input() public items: any[] = [];
    @ContentChild(TemplateRef) public itemTemplate: TemplateRef<any>;
}
