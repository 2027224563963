import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestService } from '../shared/rest.service';
import { SessionManagerService } from '../session/shared/sessionmanager.service';
import { LoginResponse } from './loginresponse';
import { AuthHttpClient } from '../core/authhttpclient';
import { tap } from 'rxjs/operators';
import { OrderListSearchService } from '../orders/order-list/shared/order-list-search.service';

@Injectable()
export class AuthenticationService extends RestService {
    constructor(private http: HttpClient,
                private sessionManager: SessionManagerService) {
        super();
    }

    public authenticate(username: string, password: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http.post<LoginResponse>(`${this.apiBase}/login_check`,
                { username: username, password: password },
                {}
            ).subscribe((data: LoginResponse) => {
                this.sessionManager.token = data.token;
                this.sessionManager.refreshToken = data.refresh_token;
                resolve(true);
            }, err => {
                this.logout();
                if (!err.status) {
                    reject(null);
                }
                reject(err);
            });
        });
    }

    public refreshToken(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http.post(`${this.apiBase}/token/refresh`, {
                refresh_token: this.sessionManager.refreshToken,
            }, {}).subscribe((data: LoginResponse) => {
                this.sessionManager.token = data.token;
                this.sessionManager.refreshToken = data.refresh_token;
                resolve(true);
            }, (err) => {
                this.logout();
                if (!err.status) {
                    reject(null);
                }
                reject(err);
            });
        });
    }

    public refreshTokenObservable(): Observable<any> {
        return this.http.post(`${this.apiBase}/token/refresh`, {
            refresh_token: this.sessionManager.refreshToken,
        }, {}).pipe(tap((data: LoginResponse) => {
            this.sessionManager.token = data.token;
            this.sessionManager.refreshToken = data.refresh_token;
            console.log(`${new Date()} Refreshing token`);
        }));
    }

    public logout() {
        this.sessionManager.clear();
    }
}
