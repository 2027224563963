import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
    selector: '[capSubmitIfValid]'
})
export class CapSubmitIfValidDirective {
    @Input() public form: FormGroup;
    @Output() public valid: EventEmitter<void> = new EventEmitter();

    constructor() {
    }

    @HostListener('click')
    public handleClick() {
        // Mark the form as dirty
        Object.keys(this.form.controls).forEach((field: string) => {
            this.form.controls[field].markAsDirty();
        });

        if (this.form.valid) {
            this.valid.emit();
        }
    }
}
