import { Injectable } from '@angular/core';
import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Crop } from './crop.model';
import { Variety } from './variety.model';
import { PaginationRequest, PaginationResult } from '../../shared/pagination/pagination.interface';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/index';

@Injectable({
    providedIn: 'root'
})
export class VarietyService extends CrudSubResourceService<Crop, Variety> {

    protected initApiResource() {
        this.apiResource = 'crops';
        this.apiSubResource = 'varieties';
    }

    public search(paginationRequest: PaginationRequest, cropIds: number[] = [], showArchived: boolean = false): Observable<PaginationResult<Variety>> {
        let params: HttpParams = this.getPaginationHttpParams(paginationRequest);
        if (cropIds.length > 0) {
            params = params.append('cropIds', cropIds.join(','));
        }

        return this.http.get(`${this.apiBase}/${this.apiSubResource}/search`, {
            headers: this.getHeaders(),
            params
        });
    }
}
