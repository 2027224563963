import { Injectable, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class CsvErrorService {
    public buildErrorFromResponse(response: any) {
        let errorPayload = response.error.error.data;

        let errors = [];
        
        _.forEach(errorPayload, (error) => {
            errors[error.row] = error.errors;
        });

        return errors;
    }
}
