import { CrudSubResourceService } from '../../../shared/crud-sub-resource.service';
import { Merchant } from '../../shared/merchant.model';
import { MerchantModifier } from './merchant-modifier.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { CommodityType } from '../../../shared/models/commodity-type.model';

export class MerchantModifierService extends CrudSubResourceService<Merchant, MerchantModifier> {
    protected initApiResource() {
        this.apiResource = 'merchants';
        this.apiSubResource = 'merchant-modifiers';
    }

    public listByCommodityType(parentResourceId: number, commodityType?: CommodityType, showArchived: boolean = false): Observable<any[]> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('archived', showArchived.toString());

        if (commodityType) {
            queryParams = queryParams.append('commodityType', commodityType.toString());
        }

        return this.http.get<MerchantModifier[]>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, {
            headers: this.getHeaders(),
            params: queryParams
        });
    }
}
