import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Merchant, MerchantContact } from '../shared/merchant.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export class MerchantContactService extends CrudSubResourceService<Merchant, MerchantContact> {
    protected initApiResource() {
        this.apiResource = 'merchants';
        this.apiSubResource = 'merchant-contacts';
    }

    public list(
        parentResourceId: number,
        {
            showArchived = false,
            onlyDepotContacts = null,
            onlyManageQuotesContacts = null
        }: {
            showArchived?: boolean,
            onlyDepotContacts?: boolean|null
            onlyManageQuotesContacts?: boolean|null
        } = {}
    ): Observable<MerchantContact[]> {
        let p = new HttpParams();

        p = p.append('archived', showArchived.toString());

        // SS - this is weak-checking on purpose - because this condition is meant to specifically catch `null` values.
        // Strong-typing this would break the effect, and either set the value to `undefined`, or `false`.
        if (onlyDepotContacts != null) {
            p = p.append('onlyDepotContacts', `${onlyDepotContacts}`);
        }

        if (onlyManageQuotesContacts != null) {
            p = p.append('onlyManageQuotesContacts', `${onlyManageQuotesContacts}`);
        }

        return this.http.get<MerchantContact[]>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`, {
            headers: this.getHeaders(),
            params: p
        });
    }
}
