import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Farmer } from '../farmers.model';
import { Observable, of } from 'rxjs';
import { Message } from './messages.model';
import { User } from '../../admin/users/shared/user.model';

export class MessagesService extends CrudSubResourceService<Farmer, Message> {
  protected initApiResource() {
    this.apiResource = 'farmers';
    this.apiSubResource = 'messages';
  }
}
