import { Component, Input } from "@angular/core";
import { ApiErrorResponse } from "../../../errors/api-error-response.model";

@Component({
    selector: 'cap-error-list',
    templateUrl: './cap-error-list.component.html'
})
export class CAPErrorListComponent {
    private _response: any = null;
    public errors: ApiValidationError[] = [];

    @Input() public set response(response: any) {
        if (response == null) {
            return;
        }

        this._response = response;

        // traverse-down object to get errors
        if (this._response.error.status === 400) {
            const errorObject: ApiErrorResponse = this._response.error.error.error;
            this.errors = errorObject.errors;
        }
    }

    constructor(
    ) {
    }
}
