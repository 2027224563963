import { Merchant } from './shared/merchant.model';
import { Observable, of } from 'rxjs';
import { PaymentTerm } from './payment-term.model';
import { CrudService } from '../../shared/crud.service';

export class PaymentTermService extends CrudService<PaymentTerm> {

    public retrieve(): Observable<PaymentTerm[]> {
        return this.http.get<PaymentTerm[]>(`${this.apiBase}/${this.apiResource}/list`, {
          headers: this.getHeaders()
        });
    }

    protected initApiResource() {
      this.apiResource = 'paymentTerms';
    }
}
