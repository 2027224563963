import { CrudService } from "../../../shared/crud.service";
import { ChemicalManufacturer } from "./chemical-manufacturer.model";
import { ChemicalBrand } from 'app/chemicals/shared/chemical.model';

export class ChemicalManufacturerService extends CrudService<ChemicalManufacturer> {

    private apiSubResource = 'chemical-brands';

    protected initApiResource() {
        this.apiResource = 'manufacturers';
    }

    public listChemicalBrands(id: number) {
        return this.http.get<ChemicalBrand[]>(`${this.apiBase}/${this.apiResource}/${id}/${this.apiSubResource}`, {
            headers: this.getHeaders()
        });
    }
}
