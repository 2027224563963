import { Injectable } from '@angular/core';
import { RestService } from '../../shared/rest.service';
import { AuthHttpClient } from '../../core/authhttpclient';
import { Observable } from 'rxjs/index';
import { MerchantAdjustment, PriceAdjustment } from './merchant-adjustment.model';
import { PaginationMeta, PaginationRequest } from '../../shared/pagination/pagination.interface';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/internal/operators';
import { CommodityType } from '../../shared/models/commodity-type.model';

@Injectable()
export class MerchantAdjustmentService extends RestService {

    constructor(private http: AuthHttpClient) {
        super();
    }

    public listHaulageBands(
        merchantIds,
        cropId: number = null,
        fertiliserTypeId: number = null,
        activePriceOnly: boolean = false,
        commodityType: CommodityType = null
    ): Observable<MerchantAdjustment[]> {
        let params = new HttpParams();
        params = params.append('activePriceOnly', activePriceOnly.toString());

        if (cropId) {
            params = params.append('cropId', cropId.toString());
        }

        if (fertiliserTypeId) {
            params = params.append('fertiliserTypeId', fertiliserTypeId.toString());
        }

        if (merchantIds instanceof Array) {
            merchantIds = merchantIds.join(',');
        }

        if (commodityType) {
            params = params.append('commodityType', commodityType.toString());
        }

        return this.http.get(`${this.apiBase}/merchants/${merchantIds}/haulage-bands`, {
            params
        });
    }

    public addHaulageBand(merchantId: number, data: any) {
        return this.http.post(`${this.apiBase}/merchants/${merchantId}/haulage-bands`, data);
    }

    public changeHaulageBand(merchantId: number, haulageBandId: number, data: any) {
        return this.http.put(`${this.apiBase}/merchants/${merchantId}/haulage-bands/${haulageBandId}`, data);
    }

    public assignHaulageBandsToCrop(merchantId: number, data: any) {
        return this.http.post(`${this.apiBase}/merchants/${merchantId}/haulage-bands/assign-to-crop`, data);
    }

    public assignHaulageBandsToFertiliserType(merchantId: number, data: any) {
        return this.http.post(`${this.apiBase}/merchants/${merchantId}/haulage-bands/assign-to-fertiliser-type`, data);
    }

    public listBagSizeAdjustments(merchantId: number, commodityType: CommodityType, activePriceOnly: boolean = false): Observable<MerchantAdjustment[]> {
        let params = new HttpParams();
        params = params.append('commodityType', commodityType.toString());
        params = params.append('activePriceOnly', activePriceOnly.toString());

        return this.http.get(`${this.apiBase}/merchants/${merchantId}/bag-size-adjustments`, {
            params
        });
    }

    public addBagSizeAdjustment(merchantId: number, data: any) {
        return this.http.post(`${this.apiBase}/merchants/${merchantId}/bag-size-adjustments`, data);
    }

    public listSeedDressingAdjustments(
        merchantIds,
        cropId: number,
        activePriceOnly: boolean = false,
        orderBy: string = 'amount'
    ): Observable<MerchantAdjustment[]> {
        let params = new HttpParams();
        params = params.append('activePriceOnly', activePriceOnly.toString());
        params = params.append('cropId', cropId.toString());
        params = params.append('orderBy', orderBy);

        if (merchantIds instanceof Array) {
            merchantIds = merchantIds.join(',');
        }

        return this.http.get(`${this.apiBase}/merchants/${merchantIds}/seed-dressing-adjustments`, {
            params
        });
    }

    public addSeedDressingAdjustment(merchantId: number, data: any) {
        return this.http.post(`${this.apiBase}/merchants/${merchantId}/seed-dressing-adjustments`, data);
    }

    public listHistoricPrices(id: number, paginationRequest: PaginationRequest): Observable<{ data: PriceAdjustment[], pagination: PaginationMeta }> {
        let params = new HttpParams();
        params = params.append('page', paginationRequest.page.toString());
        params = params.append('maxResults', paginationRequest.maxResults.toString());

        return this.http.get(`${this.apiBase}/merchant-adjustments/${id}/prices`, {
            params
        }).pipe(map((res: any) => {
            return {
                data: res.data,
                pagination: res.meta.pagination
            };
        }));
    }

    public deleteMerchantAdjustment(id: number) {
        return this.http.delete(`${this.apiBase}/merchant-adjustments/${id}`);
    }

    public createPrice(id: number, data: any) {
        return this.http.post(`${this.apiBase}/merchant-adjustments/${id}/prices`, data);
    }

    public editPrice(id: number, priceId: number, data: any) {
        return this.http.put(`${this.apiBase}/merchant-adjustments/${id}/prices/${priceId}`, data);
    }

    public deletePrice(id: number, priceId: number) {
        return this.http.delete(`${this.apiBase}/merchant-adjustments/${id}/prices/${priceId}`);
    }
}
