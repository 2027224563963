import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs/index';
import { debounceTime } from 'rxjs/internal/operators';
import * as _ from 'lodash';

@Component({
    selector: 'cap-sortable-list',
    templateUrl: './cap-sortable-list.component.html',
    styleUrls: ['./cap-sortable-list.component.scss']
})
export class CapSortableListComponent implements OnInit {
    @Input() public fieldName: string = 'name';

    @Input()
    public set items(value: any[]) {
        if (!value) {
            return;
        }
        this.itemsSubject$.next(value);
    }

    public get items(): any[] {
        return this._items;
    }

    @Output() public selectedItemsChange = new EventEmitter();

    @Input()
    public set selectedItems(value: any[]) {
        if (!value) {
            return;
        }

        this.selectedItemsSubject$.next(value);
    }

    public get selectedItems(): any[] {
        return this._selectedItems;
    }

    public itemsToAdd: any[] = [];

    private _items: any[];
    private _selectedItems: any[];

    private itemsSubject$: BehaviorSubject<any[]> = new BehaviorSubject([]);
    private selectedItemsSubject$: BehaviorSubject<any[]> = new BehaviorSubject([]);

    public ngOnInit() {
        combineLatest(this.itemsSubject$, this.selectedItemsSubject$).pipe(debounceTime(100)).subscribe(([items, selectedItems]) => {
            this._selectedItems = selectedItems;

            // Filter items to not selected ones
            this._items = items.filter((item) => {
                return !selectedItems.find((selected) => _.isEqual(selected, item));
            });

            this.selectedItemsChange.emit(selectedItems);
        });
    }

    public addItemsToSelection() {
        this.selectedItems = this._selectedItems.concat(this.itemsToAdd);
        this.itemsToAdd = [];
    }

    public removeSelectedItem(index: number) {
        // Return new array collection to trigger subject
        this.selectedItems = this._selectedItems.filter((item, itemIndex) => {
            return itemIndex !== index;
        });
    }
}
