import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { CrudService } from "../../../shared/crud.service";
import { CommodityType } from "../../../shared/models/commodity-type.model";
import { BagSize } from "./bag-size.model";

export class BagSizeService extends CrudService<BagSize> {
    protected initApiResource() {
        this.apiResource = 'bag-sizes';
    }

    // Note: uses the same endpoint as `CrudService#list`, but doesn't compile as an override.
    public listByCommodityType({ commodityType = null }: { commodityType?: CommodityType } = {}): Observable<BagSize[]> {
        let p = new HttpParams();

        if (commodityType) {
            p = p.append('commodityType', commodityType.toString());
        }

        return this.http.get<BagSize[]>(`${this.apiBase}/${this.apiResource}`, {
            headers: this.getHeaders(),
            params: p
        });
    }
}
