import { CommodityType } from './commodity-type.model';

export class CommodityTypeUtils {
    public static toSlug(commodity: CommodityType): string {
        switch (commodity) {
            case CommodityType.Chemical:
                return 'chemical';
            case CommodityType.Seed:
                return 'seed';
            case CommodityType.Fertiliser:
                return 'fertiliser';
        }
    }

    public static toDisplayString(commodity: CommodityType): string {
        switch (commodity) {
            case CommodityType.Chemical:
                return 'Chemical';
            case CommodityType.Seed:
                return 'Seed';
            case CommodityType.Fertiliser:
                return 'Fertiliser';
        }
    }

    public static slugToCommodityType(slug: string): CommodityType {
        switch (slug) {
            case CommodityTypeUtils.toSlug(CommodityType.Chemical):
                return CommodityType.Chemical;
            case CommodityTypeUtils.toSlug(CommodityType.Seed):
                return CommodityType.Seed;
            case CommodityTypeUtils.toSlug(CommodityType.Fertiliser):
                return CommodityType.Fertiliser;
            default:
                return null;
        }
    }
}
