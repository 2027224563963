import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap';

@Component({
    selector: 'cap-modal',
    templateUrl: './cap-modal.component.html',
    styleUrls: ['./cap-modal.component.scss']
})
export class CAPModalComponent implements AfterContentInit {
    @Input() modalSize: string = 'md';
    @Input() modalBackground: string = 'bg-success';
    @Input() modalOptions = { backdrop: 'static' };

    @Output() private onHidden: EventEmitter<any> = new EventEmitter();
    @Output() private onShown: EventEmitter<any> = new EventEmitter();

    @ViewChild(ModalDirective) private modal: ModalDirective;
    @ViewChild('modalHeader') public modalHeader;
    @ViewChild('modalFooter') public modalFooter;

    private _showHeader: boolean;
    private _showFooter: boolean;

    public show() {
        this.modal.show();
    }

    public hide() {
        this.modal.hide();
    }

    public onHiddenHandler() {
        this.onHidden.emit();
        $('.modal-backdrop').remove();
    }

    public onShownHandler() {
        this.onShown.emit();
    }

    public ngAfterContentInit() {
        this._showHeader = this.modalHeader.nativeElement && this.modalHeader.nativeElement.children.length > 0;
        this._showFooter = this.modalFooter.nativeElement && this.modalFooter.nativeElement.children.length > 0;
    }

    get showHeader(): boolean {
        return this._showHeader;
    }

    get showFooter(): boolean {
        return this._showFooter;
    }
}
