import { EventEmitter, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap';
import { ConfirmOptions } from './confirm-options';
import { ConfirmModalComponent } from './confirm-modal.component';
import { Subject } from 'rxjs/index';

@Injectable({
    providedIn: 'root'
})
export class ConfirmModalService {
    public onConfirm: EventEmitter<any> = new EventEmitter();
    public onCancel: EventEmitter<any> = new EventEmitter();
    public onHidden: EventEmitter<any> = new EventEmitter();

    constructor(
        private modalService: BsModalService
    ) {
    }

    public show(confirmOptions?: ConfirmOptions) {
        const options = new ConfirmOptions();
        if (confirmOptions) {
            // Override default options
            Object.assign(options, confirmOptions);
        }

        const modalRef = this.modalService.show(ConfirmModalComponent);
        const modal: ConfirmModalComponent = modalRef.content;

        modal.options = confirmOptions;
        modal.onYes.subscribe(() => {
            modalRef.hide();
            this.onConfirm.emit();
            // this.modalService.hide(modalRef);
        });

        modal.onNo.subscribe(() => {
            modalRef.hide();
            this.onCancel.emit();
        });

        this.modalService.onHidden.subscribe(() => {
            this.onHidden.emit();
        });
    }
}
