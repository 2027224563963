import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Acreage } from './acreage.model';
import { Observable, Subscriber } from 'rxjs';
import { CroppingService } from '../../admin/croppings/shared/cropping.service';
import { Farmer } from '../farmers.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionManagerService } from '../../session/shared/sessionmanager.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Cropping } from '../../admin/croppings/shared/cropping.model';
import { CroppingYear } from './croppingyear.model';
import { AuthHttpClient } from '../../core/authhttpclient';

@Injectable()
export class AcreageService extends CrudSubResourceService<Farmer, CroppingYear> {
    constructor(
        private croppingService: CroppingService,
        public http: AuthHttpClient,
        sessionManager: SessionManagerService
    ) {
        super(http, sessionManager);
    }

    protected initApiResource() {
        this.apiResource = 'farmers';
        this.apiSubResource = 'acreage';
    }

    public updateSingleYear(
        parentResourceId: number,
        data: CroppingYear,
        { includeArchived = false }: { includeArchived?: boolean } = {}
    ): Observable<CroppingYear> {
        let p = new HttpParams();
        p = p.append('archived', includeArchived.toString());

        return this.http.post<CroppingYear>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`,
            data,
            {
                headers: this.getHeaders(),
                params: p
            }
        );
    }

    public createYear(parentResourceId: number, data: CroppingYear): Observable<CroppingYear> {
        return this.http.post<CroppingYear>(`${this.apiBase}/${this.apiResource}/${parentResourceId}/${this.apiSubResource}`,
            data,
            {
                headers: this.getHeaders()
            }
        );
    }
}
