import { CrudSubResourceService } from '../../shared/crud-sub-resource.service';
import { Farmer } from '../farmers.model';
import { Merchant, MerchantContact, MerchantReference } from '../../merchants/shared/merchant.model';
import { Observable, of } from 'rxjs';

export class MerchantReferenceService extends CrudSubResourceService<Farmer, MerchantReference> {
    protected initApiResource() {
        this.apiResource = 'farmers';
        this.apiSubResource = 'merchant-references';
    }

    public addMerchantToFarmer(farmerId: number, merchantId: any): Observable<any> {
        return this.http.post(`${this.apiBase}/${this.apiResource}/${farmerId}/${this.apiSubResource}`, merchantId, {
            headers: this.getHeaders()
        });
    }

    updateReferences(farmerId: number, payload: any): Observable<any> {
        return this.http.put(`${this.apiBase}/${this.apiResource}/${farmerId}/${this.apiSubResource}`, payload, {
            headers: this.getHeaders()
        });
    }
}
